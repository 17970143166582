import {
  getCoreRowModel,
  getExpandedRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import getColumnsList from "modules/GlobalAllocation/utils";
import {customDateFilterFn} from "modules/SAF-dashboard/utils";
import PropTypes from "prop-types";
import {useUserSettings} from "providers/userSettings";
import {useEffect, useMemo, useState} from "react";
import {Table} from "reactstrap";

import {isEqual} from "lodash";
import {useParams} from "react-router-dom";
import {
  getColumns,
  renderEmptyStates,
  renderTableBody,
  renderTableHeaders,
} from "../../tableUtils";

const DocumentTable = ({
  columns,
  data,
  enableRowSelection,
  rowSelection,
  setRowSelection,
  loading,
  columnFilter,
  setColumnFilter,
  type,
  otherData,
  setFilteredIncomingRows,
  setFilteredOutGoingRows,
  showFilter,
}) => {
  const {country, division} = useParams();

  const {
    userSettings: {dateFormat, decimalFormat},
  } = useUserSettings();

  const {INCOMING_COLUMNS, OUTGOING_COLUMNS} = getColumnsList(
    country,
    division,
    dateFormat,
    decimalFormat,
  );

  const incomingColumnsList = useMemo(
    () => INCOMING_COLUMNS?.[0]?.columns?.map((item) => item.key),
    [INCOMING_COLUMNS],
  );

  const outGoingColumnsList = useMemo(
    () => OUTGOING_COLUMNS?.[0]?.columns?.map((item) => item.key),
    [OUTGOING_COLUMNS],
  );

  const isIncomingFilter = useMemo(() => {
    if (type === "Incoming") {
      return columnFilter.some((obj) => incomingColumnsList.includes(obj.id));
    }
    return false;
  }, [columnFilter, incomingColumnsList, type]);

  const isOutGoingFilter = useMemo(() => {
    if (type === "Outgoing") {
      return columnFilter.some((obj) => outGoingColumnsList.includes(obj.id));
    }
    return false;
  }, [columnFilter, outGoingColumnsList, type]);

  const newColumns = useMemo(
    () => getColumns(columns, enableRowSelection, type),
    [columns, enableRowSelection, type],
  );

  const [previousFilters, setPreviousFilters] = useState([]);
  const [recentFilter, setRecentFilter] = useState([{column: "", value: ""}]);

  useEffect(() => {
    if (isEqual(columnFilter, previousFilters)) {
      return;
    }

    const changedFilter = columnFilter.filter((newFilter) => {
      const oldFilter = previousFilters.find((f) => f.id === newFilter.id);
      return !oldFilter || oldFilter.value !== newFilter.value;
    })[0];

    if (changedFilter) {
      setRecentFilter(changedFilter);
    }

    setPreviousFilters([...columnFilter]);
  }, [columnFilter, previousFilters]);

  const table = useReactTable({
    data,
    columns: newColumns,
    enableRowSelection,
    filterFns: {
      dateRangeFilterFn: customDateFilterFn,
    },
    state: {
      rowSelection,
      columnFilters: columnFilter,
      columnPinning: {
        left: type === "Incoming" ? ["checkbox"] : [],
        right: type === "Incoming" ? [] : ["checkbox"],
      },
    },
    onColumnFiltersChange: setColumnFilter,
    onRowSelectionChange: setRowSelection,
    getSubRows: (row) => row.splitDetails ?? [],
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
  });

  useEffect(() => {
    if (table && type === "Incoming") {
      setFilteredIncomingRows(table.getPrePaginationRowModel().rows);
    }
    if (table && type === "Outgoing") {
      setFilteredOutGoingRows(table.getPrePaginationRowModel().rows);
    }
  }, [
    table,
    type,
    columnFilter,
    setFilteredIncomingRows,
    setFilteredOutGoingRows,
    loading,
  ]);

  const selectedRow = (cell) => cell.row.getIsSelected();

  const totalData = table.getPrePaginationRowModel().rows.length;

  return (
    <Table hover>
      <thead>{renderTableHeaders(table, showFilter, data)}</thead>
      <tbody>
        {renderTableBody(table, loading, totalData, selectedRow, type)}
        {renderEmptyStates(
          loading,
          totalData,
          type,
          isIncomingFilter,
          isOutGoingFilter,
          setColumnFilter,
          otherData,
          recentFilter,
        )}
      </tbody>
    </Table>
  );
};
DocumentTable.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.object),
  data: PropTypes.arrayOf(PropTypes.object),
  enableRowSelection: PropTypes.bool,
  rowSelection: PropTypes.object,
  setRowSelection: PropTypes.func,
  loading: PropTypes.bool.isRequired,
  columnFilter: PropTypes.string,
  setColumnFilter: PropTypes.func,
  type: PropTypes.string,
  otherData: PropTypes.array,
  setFilteredIncomingRows: PropTypes.func,
  setFilteredOutGoingRows: PropTypes.func,
  showFilter: PropTypes.bool,
};

export default DocumentTable;
