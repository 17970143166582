import {useQuery} from "@apollo/client";
import {Close} from "@bphxd/ds-core-react";
import {useEffect, useState} from "react";
import {FormProvider, useForm} from "react-hook-form";
import {useNavigate} from "react-router-dom";
import {
  Button,
  Form,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Spinner,
} from "reactstrap";
import dateFunctions from "utils/helpers/dateFunctions";

import {useAccount, useMsal} from "@azure/msal-react";
import {
  GET_SALE_MASTER_DETAILS,
  getCertificateAutoPopulateDetailsAPI,
} from "graphql/MassBalance/Actions/AllocateSale";
import PropTypes from "prop-types";
import {useAppSetting} from "providers/appSetting/context.js";
import {getDivisionData, getSiteDetails} from "utils/helpers/getAppSetting.js";

import {toast} from "react-toastify";
import "../../index.css";
import CertificationDetails from "./CertificationDetails";

import ProductDetails from "./ProductDetails";
import RecipientDetails from "./RecipientDetails";
import StepsAssignToSale from "./StepsAssignToSale";

const AssignToSaleModal = ({isVisibleModal, rowData, divisionCode}) => {
  const {accounts} = useMsal();
  const account = useAccount(accounts[0]);
  const {appSetting} = useAppSetting();
  const countryId = appSetting?.currentCountryMappingData?.countryId;

  const siteReferenceData = getSiteDetails(countryId);
  const divisionData = getDivisionData(divisionCode);

  const navigate = useNavigate();

  const {data: masterDetails} = useQuery(GET_SALE_MASTER_DETAILS);

  const [isModalVisible, setModalVisible] = useState(isVisibleModal);
  const [activeStep, setActiveStep] = useState(0);
  const [isProcessing, setProcessing] = useState(false);

  useEffect(() => {
    setModalVisible(isVisibleModal);
  }, [isVisibleModal]);

  const methods = useForm({
    mode: "onChange",

    shouldFocusError: true,
  });

  const {getValues, setError} = methods;

  const validateRecipientDetails = () => {
    const values = getValues();
    console.log("values", values);
    let isValid = true;
    // Required fields
    if (values.recipientName === "" || values.recipientName === undefined) {
      setError("recipientName", {
        type: "manual",
        message: "Please select name of recipient",
      });
      isValid = false;
    }
    if (
      values.recipientReceiptName === "" ||
      values.recipientReceiptName === undefined
    ) {
      setError("recipientReceiptName", {
        type: "manual",
        message: "Please select  name of receipt/receiving point",
      });
      isValid = false;
    }
    if (
      values.recipientAddress === "" ||
      values.recipientAddress === undefined
    ) {
      setError("recipientAddress", {
        type: "manual",
        message: "Please enter address of recipient",
      });
      isValid = false;
    }
    if (
      values.recipientReceiptAddress === "" ||
      values.recipientReceiptAddress === undefined
    ) {
      setError("recipientReceiptAddress", {
        type: "manual",
        message: "Please enter address of receipt/receiving point",
      });
      isValid = false;
    }
    if (values.dispatchDate === "" || values.dispatchDate === undefined) {
      setError("dispatchDate", {
        type: "manual",
        message: "Please enter dispatch date",
      });
      isValid = false;
    }
    if (values.motRecipient === "" || values.motRecipient === undefined) {
      setError("motRecipient", {
        type: "manual",
        message: "Please select mode of transportation",
      });
      isValid = false;
    }
    return isValid;
  };

  const onSubmit = async () => {
    const isValid = validateRecipientDetails();
    if (isValid) {
      setProcessing(true);
      const formData = getValues();
      console.log(
        "formData",
        formData,
        rowData,
        siteReferenceData,
        divisionData,
      );
      const inboundRecord = rowData?.original?.inboundRecord;

      const params = {
        ...formData,
        inboundEuQtyId: rowData?.inboundRecord?.inboundEuQtyId,
        sdNumber: rowData?.inboundRecord?.sdNumber,
        createdBy: account.username,
        rowData,
        siteReferenceId: siteReferenceData?.siteReferenceId,
        divisionId: divisionData?.divisionId,
      };

      const {data: dataCert} = await getCertificateAutoPopulateDetailsAPI({
        businessPartnerCode: formData?.recipientName,
        mbLocationGroupId: rowData.original.inboundRecord.mbLocationGroupId,
        certificationSystem: formData?.certificationSystem,
        templateType: formData?.templateType,
        vesselName: formData?.vesselName,
        dispatchDate: dateFunctions.formatDatePickerDate(
          formData?.dispatchDate,
        ),
        inboundEuQtyId: inboundRecord?.mbInboundQtyEuId,
        motRecipient: formData?.motRecipient,
        sdNumber: inboundRecord?.incomingDocument,
        issueDate: dateFunctions.formatDatePickerDate(formData?.issueDate),
        siteReferenceId: siteReferenceData?.siteReferenceId,
        divisionId: divisionData?.divisionId,
      });

      if (dataCert?.bioLcGetMassBalanceAutoPopulatedData?.statusCode === 500) {
        toast.error(dataCert?.bioLcGetMassBalanceAutoPopulatedData?.message);
      } else {
        navigate(`/mass-balance/generate-certificate/spain/${divisionCode}`, {
          state: JSON.stringify(params),
        });
      }
    }
  };

  const validateCertificationDetails = () => {
    const values = getValues();
    console.log("values", values);
    let isValid = true;
    // Required fields
    if (
      values.certificationSystem === "" ||
      values.certificationSystem === undefined
    ) {
      setError("certificationSystem", {
        type: "manual",
        message: "Please select certification system",
      });
      isValid = false;
    }
    if (values.templateType === "" || values.templateType === undefined) {
      setError("templateType", {
        type: "manual",
        message: "Please select template",
      });
      isValid = false;
    }
    if (values.issueDate === "" || values.issueDate === undefined) {
      setError("issueDate", {
        type: "manual",
        message: "Please enter date of issuance",
      });
      isValid = false;
    }
    return isValid;
  };

  const validateProductDetails = () => {
    const values = getValues();
    console.log("values", values);
    let isValid = true;
    // Required fields
    if (values.productType === "" || values.productType === undefined) {
      setError("productType", {
        type: "manual",
        message: "Please select type of product",
      });
      isValid = false;
    }
    if (values.uom === "" || values.uom === undefined) {
      setError("uom", {
        type: "manual",
        message: "Please select unit of measure",
      });
      isValid = false;
    }
    if (values.quantity === "" || values.quantity === undefined) {
      setError("quantity", {
        type: "manual",
        message: "Please enter quantity",
      });
      isValid = false;
    }
    if (
      values.fossilFuelComparator === "" ||
      values.fossilFuelComparator === undefined
    ) {
      setError("fossilFuelComparator", {
        type: "manual",
        message: "Please select fossil fuel comparator",
      });
      isValid = false;
    }
    return isValid;
  };

  const handleContinueClick = () => {
    if (activeStep === 0) {
      const isValid = validateCertificationDetails();
      if (isValid) {
        setActiveStep(activeStep + 1);
      }
    }
    if (activeStep === 1) {
      const isValid = validateProductDetails();
      if (isValid) {
        setActiveStep(activeStep + 1);
      }
    }
  };

  return (
    <div>
      <Modal
        size="lg"
        isOpen={isModalVisible}
        className="modal-dialog-centered mb-action-modal-666"
      >
        <ModalHeader
          className="!border-b-[1px] !border-gray-200 mb-5 text-xl"
          close={<Close onClick={() => setModalVisible(false)} />}
        >
          Allocate to sale
        </ModalHeader>
        <ModalBody className="text-center pt-0">
          <FormProvider {...methods}>
            <Form
              className="flex flex-col gap-5 text-left"
              id="assign-to-sales-form"
            >
              <StepsAssignToSale
                activeStep={activeStep}
                setActiveStep={setActiveStep}
              />
              {activeStep === 0 && (
                <CertificationDetails
                  rowData={rowData}
                  masterDetails={masterDetails}
                />
              )}
              {activeStep === 1 && (
                <ProductDetails
                  rowData={rowData}
                  masterDetails={masterDetails}
                />
              )}
              {activeStep === 2 && (
                <RecipientDetails
                  rowData={rowData}
                  masterDetails={masterDetails}
                />
              )}
            </Form>
          </FormProvider>
        </ModalBody>

        <ModalFooter className="p-0 d-block">
          <div className="row g-0 m-0 modal-footer-row">
            <div className="col-6 d-grid">
              <Button
                color="darker-tertiary"
                size="lg"
                className="border-0 rounded-0 py-4 opacity-80 opacity-100-hover bg-transparent"
                onClick={() => setModalVisible(false)}
              >
                Cancel
              </Button>
            </div>
            <div className="col-6 d-grid">
              {activeStep === 2 && (
                <Button
                  color="darker-tertiary"
                  size="lg"
                  className="border-0 rounded-0 py-4 bg-transparent text-default"
                  type="button"
                  onClick={() => onSubmit()}
                >
                  {isProcessing && (
                    <Spinner size="sm" className="btn-icon-prefix" />
                  )}
                  Finalize certificate
                </Button>
              )}
              {activeStep !== 2 && (
                <Button
                  color="darker-tertiary"
                  size="lg"
                  className="border-0 rounded-0 py-4 bg-transparent text-default"
                  onClick={() => {
                    handleContinueClick();
                  }}
                >
                  {isProcessing && (
                    <Spinner size="sm" className="btn-icon-prefix" />
                  )}
                  Continue
                </Button>
              )}
            </div>
          </div>
        </ModalFooter>
      </Modal>
    </div>
  );
};

AssignToSaleModal.propTypes = {
  isVisibleModal: PropTypes.bool,
  rowData: PropTypes.object,
  divisionCode: PropTypes.string,
};

export default AssignToSaleModal;
