import moment from "moment";
import {DDV, TDV} from "../components/view-document/utils";

const isAfter5Oct2015 = (date) => {
  if (date) {
    return moment(date, "YYYY-MM-DD").isAfter("2015-10-05");
  }
  return "";
};

export const defaultValues = {
  additionalInfo: "",
  additionalNotes: "",
  agriculturalBiomassIntermediateCropFlag: null,
  agriculturalBiomassLowRiskFlag: null,
  availableBioProductTons: "",
  productEnergyContent: "",
  certificateNumber: "",
  certificationSystem: "",
  chainOfCustodyOption: "",
  contractNumber: "",
  customerRef: "",
  dealNotes: "",
  deliveryNotes: "",
  density: "",
  densityUom: "",
  differenceVol: "",
  euRedCompliantFlag: null,
  wasteOrAnimalByProductPermitNumber: "",
  supportForFuelProductionOrFuelPrecursorReceivedFlag: null,
  ifYesSpecifySupportNatureAndScheme: "",
  expiryDate: "",
  feedstockCountryCode: "",
  dateOfIssuance: "",
  feedstockMonth: "",
  feedstockQty: "",
  feedstockQtyUom: "",
  feedstockQuarter: "",
  fossilNotes: "",
  ghgEccr: 0,
  ghgEccs: 0,
  ghgEec: 0,
  ghgEee: 0,
  ghgEl: 0,
  ghgEmissionIn: "",
  ghgEmissionOut: "",
  ghgEmissionPercentageIn: "",
  ghgEmissionPercentageOut: "",
  ghgEp: 0,
  ghgEsca: 0,
  ghgEtd: 0,
  ghgEu: 0,
  ghgTotal: 0,
  importedInvoiceVol: "",
  invoiceProviderName: "",
  isccCompliantFlag: null,
  materialDispatchDatez: "",
  materialSustainabilityCriteriaFlag: null,
  mbLocation: "",
  motDistanceKm: "",
  motFromRefinery: "",
  motToRefinery: "",
  observationNotes: "",
  operationDesc: "",
  orderNumber: "",
  originDestinationDesc: "",
  physicalReceiptDate: "",
  processedFeedstockTons: "",
  processType: "",
  productConcludedQty: "",
  productInvoiceVol: "",
  productionLossTons: "",
  productNetVol: "",
  productType: "",
  rawExpiryDate: "",
  rawIssueDate: "",
  rawMaterialCultivationCountryName: "",
  rawMaterialDeliveryDate: "",
  rawMaterialOriginCountryName: "",
  rawMaterialType: "",
  rawMaterialWasteOrResidueFlag: null,
  recipientAddress: "",
  recipientName: "",
  recipientReceiptAddress: "",
  recipientReceiptAddressSameFlag: null,
  refineryDischargeVol: "",
  refineryLoadedVol: "",
  sdNumber: "",
  supplierAddress: "",
  supplierDispatchAddress: "",
  supplierDispatchAddressSameFlag: null,
  supplierInvoiceVol: "",
  supplierName: "",
  thirdPartyName: "",
  transportationEmissionId: "",
  transportationEmissionRoute: "",
  transportLossTons: "",
  vesselAtHuelva: "",
  vesselAtTespa: "",
  emissionForTransport: false,
  defaultValueAppliedFlag: null,
  producerInstallationDate: "",
  disaggregatedDefaultValueOilFlag: null,
  disaggregatedDefaultValueSoiln2oFlag: null,
  bonusCo245gManureUsedFlag: null,
  bonusCo229gManureUsedFlag: null,
  supplychainIncentiveReceivedFlag: null,
  supplychainIncentiveReceivedName: "",
  nuts2Region: "",
  splitDetails: [],
};

export const getProps = (documentData, dateFormat) => {
  const {
    additionalInfo = "",
    additionalNotes = "",
    agriculturalBiomassIntermediateCropFlag = null,
    agriculturalBiomassLowRiskFlag = null,
    availableBioProductTons = "",
    productEnergyContent = "",
    certificateNumber = "",
    certificationSystem = "",
    chainOfCustodyOption = "",
    contractNumber = "",
    customerRef = "",
    dealNotes = "",
    deliveryNotes = "",
    density = "",
    densityUom = "",
    differenceVol = "",
    euRedCompliantFlag = null,
    wasteOrAnimalByProductPermitNumber = "",
    supportForFuelProductionOrFuelPrecursorReceivedFlag = null,
    ifYesSpecifySupportNatureAndScheme = "",
    expiryDate = "",
    feedstockCountryCode = "",
    dateOfIssuance = "",
    feedstockMonth = "",
    feedstockQty = "",
    feedstockQtyUom = "",
    feedstockQuarter = "",
    fossilNotes = "",
    ghgEccr = 0,
    ghgEccs = 0,
    ghgEec = 0,
    ghgEee = 0,
    ghgEl = 0,
    ghgEmissionIn = "",
    ghgEmissionOut = "",
    ghgEmissionPercentageIn = "",
    ghgEmissionPercentageOut = "",
    ghgEp = 0,
    ghgEsca = 0,
    ghgEtd = 0,
    ghgEu = 0,
    ghgTotal = 0,
    importedInvoiceVol = "",
    invoiceProviderName = "",
    isccCompliantFlag = null,
    materialDispatchDatez = "",
    materialSustainabilityCriteriaFlag = null,
    mbLocation = "",
    motDistanceKm = "",
    motFromRefinery = "",
    motToRefinery = "",
    observationNotes = "",
    operationDesc = "",
    orderNumber = "",
    originDestinationDesc = "",
    physicalReceiptDate = "",
    processedFeedstockTons = "",
    processType = "",
    productConcludedQty = "",
    productInvoiceVol = "",
    productionLossTons = "",
    productNetVol = "",
    productType = "",
    rawExpiryDate = "",
    rawIssueDate = "",
    rawMaterialCultivationCountryName = "",
    rawMaterialDeliveryDate = "",
    rawMaterialOriginCountryName = "",
    rawMaterialType = "",
    rawMaterialWasteOrResidueFlag = null,
    recipientAddress = "",
    recipientName = "",
    recipientReceiptAddress = "",
    recipientReceiptAddressSameFlag = null,
    refineryDischargeVol = "",
    refineryLoadedVol = "",
    sdNumber = "",
    supplierAddress = "",
    supplierDispatchAddress = "",
    supplierDispatchAddressSameFlag = null,
    supplierInvoiceVol = "",
    supplierName = "",
    thirdPartyName = "",
    transportationEmissionId = "",
    transportationEmissionRoute = "",
    transportLossTons = "",
    vesselAtHuelva = "",
    vesselAtTespa = "",
    defaultValueAppliedFlag = null,
    producerInstallationDate = "",
    disaggregatedDefaultValueOilFlag = null,
    disaggregatedDefaultValueSoiln2oFlag = null,
    bonusCo245gManureUsedFlag = null,
    bonusCo229gManureUsedFlag = null,
    supplychainIncentiveReceivedFlag = null,
    supplychainIncentiveReceivedName = "",
    nuts2Region = "",

    splitDetails = [],
    bvAuditCreatedDatez = "",
    bvAuditChangedDatez = "",
    mbBalanceGroupId = "",
    mbLocationGroupId = "",
    previousVersionSdNumber = "",
    previousVersionId = "",
    productQty = "",
    productQtyUom = "",
  } = documentData;
  const props = {
    sdNumber,
    mbBalanceGroupId,
    mbLocationGroupId,
    bvAuditCreatedDatez,
    bvAuditChangedDatez,
    feedstockCountryCode,
    rawIssueDate,
    dateOfIssuance:
      dateOfIssuance == null || dateOfIssuance === ""
        ? ""
        : moment(dateOfIssuance).format(dateFormat),
    feedstockMonth,
    feedstockQuarter,
    feedstockQty,
    feedstockQtyUom,
    density,
    densityUom,
    supplierName,
    supplierAddress,
    supplierDispatchAddress,
    supplierDispatchAddressSameFlag,
    recipientName,
    recipientAddress,
    recipientReceiptAddress,
    recipientReceiptAddressSameFlag,
    certificationSystem,
    certificateNumber,
    materialDispatchDatez:
      materialDispatchDatez == null || materialDispatchDatez === ""
        ? ""
        : moment(materialDispatchDatez).format(dateFormat),
    rawExpiryDate,
    expiryDate,
    contractNumber,
    orderNumber,
    customerRef,
    transportLossTons,
    productNetVol,
    productInvoiceVol,
    refineryLoadedVol,
    refineryDischargeVol,
    differenceVol,
    invoiceProviderName,
    supplierInvoiceVol,
    importedInvoiceVol,
    observationNotes,
    processedFeedstockTons,
    productConcludedQty,
    availableBioProductTons,
    productEnergyContent,
    productionLossTons,
    motFromRefinery,
    motDistanceKm,
    motToRefinery,
    originDestinationDesc,
    thirdPartyName,
    operationDesc,
    rawMaterialType,
    rawMaterialCultivationCountryName,
    physicalReceiptDate:
      physicalReceiptDate == null || physicalReceiptDate === ""
        ? ""
        : moment(physicalReceiptDate).format(dateFormat),
    mbLocation,
    ghgEec: parseFloat(ghgEec ?? "0"),
    ghgEl: parseFloat(ghgEl ?? "0"),
    ghgEp: parseFloat(ghgEp ?? "0"),
    ghgEtd: ghgEtd === DDV ? ghgEtd : parseFloat(ghgEtd ?? "0"),
    ghgEu: parseFloat(ghgEu ?? "0"),
    ghgEsca: parseFloat(ghgEsca ?? "0"),
    ghgEccs: parseFloat(ghgEccs ?? "0"),
    ghgEccr: parseFloat(ghgEccr ?? "0"),
    ghgEee: parseFloat(ghgEee ?? "0"),
    ghgTotal:
      ghgTotal === TDV || ghgTotal === DDV
        ? ghgTotal
        : parseFloat(ghgTotal ?? "0"),
    ghgEmissionIn,
    ghgEmissionPercentageIn,
    ghgEmissionOut,
    ghgEmissionPercentageOut,
    productType,
    processType,
    rawMaterialOriginCountryName,
    rawMaterialDeliveryDate,
    chainOfCustodyOption,
    additionalInfo,
    additionalNotes,
    deliveryNotes,
    dealNotes,
    fossilNotes,
    vesselAtHuelva,
    vesselAtTespa,
    transportationEmissionId,
    transportationEmissionRoute,
    isccCompliantFlag: isccCompliantFlag == null ? "" : isccCompliantFlag,
    euRedCompliantFlag: euRedCompliantFlag == null ? "" : euRedCompliantFlag,
    wasteOrAnimalByProductPermitNumber,
    supportForFuelProductionOrFuelPrecursorReceivedFlag,
    ifYesSpecifySupportNatureAndScheme:
      ifYesSpecifySupportNatureAndScheme === null
        ? ""
        : ifYesSpecifySupportNatureAndScheme,
    materialSustainabilityCriteriaFlag,
    agriculturalBiomassIntermediateCropFlag,
    agriculturalBiomassLowRiskFlag,
    rawMaterialWasteOrResidueFlag,
    emissionForTransport:
      transportationEmissionRoute !== null &&
      transportationEmissionRoute !== "",
    defaultValueAppliedFlag,
    producerInstallationDate: isAfter5Oct2015(producerInstallationDate),
    disaggregatedDefaultValueOilFlag,
    disaggregatedDefaultValueSoiln2oFlag,
    bonusCo245gManureUsedFlag,
    bonusCo229gManureUsedFlag,
    supplychainIncentiveReceivedFlag,
    supplychainIncentiveReceivedName,
    nuts2Region,
    replacePreviousVersion: !!previousVersionId,
    previousVersionSdNumber,
    previousVersionId,
    productQty,
    productQtyUom,
    splitDetails: splitDetails?.map((split) => ({
      physicalReceiptDate:
        split.physicalReceiptDate == null || split.physicalReceiptDate === ""
          ? ""
          : moment(split.physicalReceiptDate).format(dateFormat),
      ghgEec: parseFloat(split.ghgEec ?? "0"),
      ghgEl: parseFloat(split.ghgEl ?? "0"),
      ghgEp: parseFloat(split.ghgEp ?? "0"),
      ghgEtd:
        split.ghgEtd === DDV ? split.ghgEtd : parseFloat(split.ghgEtd ?? "0"),
      ghgEu: parseFloat(split.ghgEu ?? "0"),
      ghgEsca: parseFloat(split.ghgEsca ?? "0"),
      ghgEccs: parseFloat(split.ghgEccs ?? "0"),
      ghgEccr: parseFloat(split.ghgEccr ?? "0"),
      ghgEee: parseFloat(split.ghgEee ?? "0"),
      ghgTotal:
        split.ghgTotal === TDV || split.ghgTotal === DDV
          ? split.ghgTotal
          : parseFloat(split.ghgTotal ?? "0"),
      feedstockQty: parseFloat(split.feedstockQty ?? "0"),
      originCountryName: split.originCountryName,
      transportationEmissionId: split.transportationEmissionId,
      transportationEmissionRoute: split.transportationEmissionRoute,
      defaultValueAppliedFlag: split.defaultValueAppliedFlag,
    })),
  };
  return props;
};

export default {defaultValues, getProps};
