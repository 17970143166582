import {Kebab24} from "@bphxd/ds-core-react/lib/icons";
import PropTypes from "prop-types";
import {useNavigate} from "react-router-dom";
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap";
import {RuleStatus} from "../content/RuleStatus";

const RenderDropdown = ({
  onOpenDeleteModal,
  onEditClick,
  ruleVersionId,
  status,
}) => {
  const navigate = useNavigate();

  const renderItems = () => {
    switch (status) {
      case RuleStatus.DRAFT.message:
        return (
          <>
            <DropdownItem onClick={onEditClick}>Edit details</DropdownItem>

            <DropdownItem
              onClick={() =>
                navigate(
                  `/compliance-rules-engine/rule-editor/${ruleVersionId}`,
                )
              }
            >
              Edit block details
            </DropdownItem>
            <DropdownItem onClick={onOpenDeleteModal}>Delete</DropdownItem>
          </>
        );
      case RuleStatus.PUBLISHED.message:
        return (
          <>
            <DropdownItem
              onClick={() =>
                navigate(
                  `/compliance-rules-engine/rule-editor/${ruleVersionId}`,
                )
              }
            >
              View details
            </DropdownItem>
            <DropdownItem
              onClick={() =>
                navigate(
                  `/compliance-rules-engine/rule-editor/${ruleVersionId}`,
                )
              }
            >
              View block details
            </DropdownItem>
            <DropdownItem onClick={onOpenDeleteModal}>Delete</DropdownItem>
          </>
        );
      case RuleStatus.DELETED.message:
        return (
          <>
            <DropdownItem
              onClick={() =>
                navigate(
                  `/compliance-rules-engine/rule-editor/${ruleVersionId}`,
                )
              }
            >
              View details
            </DropdownItem>
            <DropdownItem
              onClick={() =>
                navigate(
                  `/compliance-rules-engine/rule-editor/${ruleVersionId}`,
                )
              }
            >
              View block details
            </DropdownItem>
            <DropdownItem disabled>Delete</DropdownItem>
          </>
        );
      default:
        return <DropdownItem>No actions available</DropdownItem>;
    }
  };

  return (
    <UncontrolledDropdown
      direction="down"
      className="compliance-action-dropdown"
    >
      <DropdownToggle
        color="standard-quartenary"
        className="!px-0 compliance-action-dropdown-button"
      >
        <Kebab24 />
      </DropdownToggle>
      <DropdownMenu>{renderItems()}</DropdownMenu>
    </UncontrolledDropdown>
  );
};

RenderDropdown.propTypes = {
  onOpenDeleteModal: PropTypes.func.isRequired,
  onEditClick: PropTypes.func.isRequired,
  ruleVersionId: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
};

export default RenderDropdown;
