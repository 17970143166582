import {gql} from "@apollo/client";

export const COMPLIANCE_RULES_LANDING_LIST_DISPLAY = gql`
  query LandingPageList($countryCode: String) {
    bioLcComplianceRulesEngineGetRules(event: {countryCode: $countryCode}) {
      error
      statusCode
      rules {
        bvAuditCreatedDatez
        countryCode
        ruleMasterId
        ruleName
        countryName
      }
    }
  }
`;

export const DELETE_RULE = gql`
  mutation DeleteRule(
    $ruleMasterId: String
    $countryCode: String
    $ruleName: String
    $userId: String
  ) {
    bioLcComplianceRulesEngineCreateUpdateRule(
      event: {
        countryCode: $countryCode
        operation: "delete"
        ruleMasterId: $ruleMasterId
        ruleName: $ruleName
        userId: $userId
      }
    ) {
      error
      statusCode
    }
  }
`;

export const COMPLIANCE_RULES_ADD_RULE = gql`
  mutation addRuleNameMutation($event: GetCreateUpdateRuleRequest!) {
    bioLcComplianceRulesEngineCreateUpdateRule(event: $event) {
      error
      statusCode
      ruleMasterId
    }
  }
`;

export const COMPLIANCE_RULES_EDIT_RULE = gql`
  mutation editRuleNameMutation(
    $ruleMasterId: String
    $countryCode: String
    $ruleName: String
    $userId: String
  ) {
    bioLcComplianceRulesEngineCreateUpdateRule(
      event: {
        countryCode: $countryCode
        operation: "update"
        ruleMasterId: $ruleMasterId
        ruleName: $ruleName
        userId: $userId
      }
    ) {
      error
      statusCode
    }
  }
`;

export const GET_EU_COUNTRIES = gql`
  query EUGetCountries {
    bioLcCoproEuGetCountries {
      code
      name
    }
  }
`;

export const POPULATE_COUNTRY_DROPDOWN = gql`
  query GetCountries {
    bioLcGlobNaviGetModuleCatalogue {
      countryCode
      countryName
    }
  }
`;

export const COMPLIANCE_RULES_UPDATE_RULE = gql`
  mutation updateRuleNameMutation($event: GetCreateUpdateRuleRequest!) {
    bioLcComplianceRulesEngineCreateUpdateRule(event: $event) {
      error
      statusCode
    }
  }
`;

export default {COMPLIANCE_RULES_LANDING_LIST_DISPLAY};
