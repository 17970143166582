import {isEmpty} from "lodash";
import moment from "moment";
import {DATE_RANGE_SIZE} from "./constants/common";

const generateBreadcrumbItems = (
  country,
  cost_calc_urls,
  optionalItems = [],
) => {
  const commonItems = [
    {text: "BioVerse", link: "/"},
    {
      text: "Spain",
      link: "/spain",
    },
    {
      text: `Co-processing`,
      link: cost_calc_urls[`${country}CoprocessingPage`],
    },
  ];

  const allItems = commonItems.concat(optionalItems);

  return allItems.map((item) => {
    return {
      text: item.text,
      link: item.link,
    };
  });
};

export const formatNumber = (value, decimalFormat, decimalPointNumbers) => {
  if (
    value !== undefined &&
    value !== null &&
    value !== "None" &&
    value !== "" &&
    value !== "NA" &&
    value !== "N/A"
  ) {
    return decimalFormat === "European Style"
      ? value?.toLocaleString("de-DE", {
          minimumFractionDigits: decimalPointNumbers,
        })
      : value?.toLocaleString("en-GB", {
          minimumFractionDigits: decimalPointNumbers,
        });
  }
  return "0";
};

export const capitalizeFirstLetter = (str) => {
  // Check if the string is empty or null
  if (!str) return str;

  // Capitalize the first letter and concatenate it with the rest of the string
  return str.charAt(0).toUpperCase() + str.slice(1);
};

function formatDate(date) {
  // Get date components
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Month is zero-indexed, so add 1
  const day = String(date.getDate()).padStart(2, "0");
  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");
  const seconds = String(date.getSeconds()).padStart(2, "0");

  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}+00:00`;
}

export function parseDateString(dateString) {
  if (isEmpty(dateString)) return "";
  const parts = dateString.split("/"); // Split the date string by '/'
  const day = parseInt(parts[0], 10); // Parse the day part
  const month = parseInt(parts[1], 10) - 1; // Parse the month part (months are 0 indexed)
  const year = parseInt(parts[2], 10); // Parse the year part

  // Create and return a Date object
  return formatDate(new Date(year, month, day));
}

export function createDateFromDDMMYYYY(dateString) {
  const parts = dateString.split("/");
  // Ensure parts array has 3 elements
  if (parts.length !== 3) {
    throw new Error(
      "Invalid date format. Please provide date in DD/MM/YYYY format.",
    );
  }

  // Parse day, month, and year from parts array
  const day = parseInt(parts[0], 10);
  const month = parseInt(parts[1], 10) - 1; // Month is 0-indexed in JavaScript
  const year = parseInt(parts[2], 10);

  // Create a new Date object
  return new Date(year, month, day);
}

export const customDateFilterFn = (row, columnId, value) => {
  if (value && value.length === DATE_RANGE_SIZE && row.getValue(columnId)) {
    const startDate = createDateFromDDMMYYYY(value[0]);
    const endDate = createDateFromDDMMYYYY(value[1]);
    const date = createDateFromDDMMYYYY(
      moment(row.getValue(columnId)).format("DD/MM/YYYY"),
    );

    return date >= startDate && date <= endDate;
  }
  return true;
};

export const getUniqueList = (data, key) => {
  const allData = data.map((item) => item[key]).filter(Boolean);
  return [...new Set(allData)];
};

// Generate messages for task notification for different types of status
export const generateMessage = (total, MESSAGE) => {
  let message;
  if (total === 0) {
    message = MESSAGE.NO_DOCUMENT;
  } else if (total === 1) {
    message = MESSAGE.ONE_DOCUMENT;
  } else {
    message = `${total} ${MESSAGE.MANY_DOCUMENTS}`;
  }
  return message;
};

export const getStartAndEndDates = (month, year) => {
  // Create a new date object for the first day of the month
  const startDate = new Date(year, month - 1, 1);
  // Create a new date object for the last day of the month
  const endDate = new Date(year, month, 0);

  // Format the dates as "DD/MM/YYYY"
  const startDateFormat =
    startDate.getDate().toString().padStart(2, "0") +
    "/" +
    (startDate.getMonth() + 1).toString().padStart(2, "0") +
    "/" +
    startDate.getFullYear();
  const endDateFormat =
    endDate.getDate().toString().padStart(2, "0") +
    "/" +
    (endDate.getMonth() + 1).toString().padStart(2, "0") +
    "/" +
    endDate.getFullYear();

  // Return the dates in an array
  return [startDateFormat, endDateFormat];
};

export const getFilterValue = (cell, filterId) => {
  const context = cell.getContext();
  const tableState = context ? context.table.getState() : null;
  const columnFilters = tableState ? tableState.columnFilters : [];

  const filterItem = columnFilters.find((item) => item.id === filterId);

  return filterItem ? filterItem.value : null;
};

export default generateBreadcrumbItems;
