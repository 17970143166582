import PropTypes from "prop-types";
import React from "react";
import {Button} from "reactstrap";
import {COUNTRY_POLAND, COUNTRY_UK} from "constants/countryDetails";
import {Controller, useForm} from "react-hook-form";
import FormDropdown from "../FormDropdown/FormDropdown";

const DocManagerFilter = ({
  onSubmit,
  supplierList,
  receivingPointList,
  supplierName,
  setColumnFilter,
  setShowFilter,
  statusList,
  productList,
  productName,
  receivingPointName,
  statusName,
  selectedCountry,
  originList,
  originName,
  setPageIndex,
}) => {
  const {handleSubmit, control, reset, getValues, setValue} = useForm({
    defaultValues: {
      supplier: "",
      product: "",
      receivingPoint: "",
      status: "",
    },
    values: {
      supplier: supplierName,
      product: productName,
      receivingPoint: receivingPointName,
      status: statusName,
      origin: originName,
    },
  });

  const handleFormSubmit = ({field, data}) => {
    setValue(field, data);
    onSubmit({
      supplierName: getValues("supplier"),
      productType: getValues("product"),
      recipientReceiptAddress: getValues("receivingPoint"),
      state: getValues("status"),
      countryCode: getValues("origin"),
    });
  };

  const handleClearForm = () => {
    reset();
    onSubmit(undefined);
    setColumnFilter([]);
    setPageIndex(0);
  };

  return (
    <div className="items-start w-full sm:items-center sm:flex-row gap-y-2 sm:gap-x-3">
      <form onSubmit={handleSubmit(handleFormSubmit)}>
        <div className="w-full flex flex-row justify-between ">
          <div className="flex gap-4">
            <div className="w-64 mr-3">
              <Controller
                control={control}
                name="supplier"
                render={({field}) => {
                  return (
                    <FormDropdown
                      label="Supplier"
                      options={supplierList.map((supplier) => supplier)}
                      placeholder="Select"
                      values={supplierList.map((supplier) => supplier)}
                      hookToBody
                      menuClassName="doc-manager-filter-dropdown"
                      menuItemClassName={{width: "auto"}}
                      onSelect={(d) =>
                        handleFormSubmit({field: "supplier", data: d})
                      }
                      {...field}
                    />
                  );
                }}
              />
            </div>
            {selectedCountry?.toLowerCase() === COUNTRY_UK && (
              <div className="w-64 mr-3">
                <Controller
                  control={control}
                  name="receivingPoint"
                  render={({field}) => {
                    return (
                      <FormDropdown
                        label="Receiving Point"
                        options={receivingPointList.map(
                          (receivingPoint) => receivingPoint,
                        )}
                        placeholder="Select"
                        values={receivingPointList.map(
                          (receivingPoint) => receivingPoint,
                        )}
                        hookToBody
                        menuClassName="doc-manager-filter-dropdown "
                        onSelect={(d) =>
                          handleFormSubmit({field: "receivingPoint", data: d})
                        }
                        {...field}
                      />
                    );
                  }}
                />
              </div>
            )}
            <div className="w-64 mr-3">
              <Controller
                control={control}
                name="product"
                render={({field}) => {
                  return (
                    <FormDropdown
                      label="Product"
                      options={productList.map((product) => product)}
                      placeholder="Select"
                      values={productList.map((product) => product)}
                      hookToBody
                      menuClassName="doc-manager-filter-dropdown "
                      onSelect={(d) =>
                        handleFormSubmit({field: "product", data: d})
                      }
                      {...field}
                    />
                  );
                }}
              />
            </div>
            {selectedCountry?.toLowerCase() === COUNTRY_POLAND && (
              <div className="w-64 mr-3">
                <Controller
                  control={control}
                  name="origin"
                  render={({field}) => {
                    return (
                      <FormDropdown
                        label="Origin"
                        options={originList.map((origin) => origin)}
                        placeholder="Select"
                        values={originList.map((origin) => origin)}
                        hookToBody
                        menuClassName="doc-manager-filter-dropdown "
                        onSelect={(d) =>
                          handleFormSubmit({field: "origin", data: d})
                        }
                        {...field}
                      />
                    );
                  }}
                />
              </div>
            )}
            <div className="w-64 mr-3">
              <Controller
                control={control}
                name="status"
                render={({field}) => {
                  return (
                    <FormDropdown
                      label="Status"
                      options={statusList.map((status) => status.label)}
                      placeholder="Select"
                      values={statusList.map((status) => status.value)}
                      hookToBody
                      menuClassName="doc-manager-filter-dropdown "
                      onSelect={(d) =>
                        handleFormSubmit({field: "status", data: d})
                      }
                      {...field}
                    />
                  );
                }}
              />
            </div>
          </div>
          <div className="flex flex-col-reverse">
            <Button
              className="padded btn btn-light rounded-0 border"
              onClick={handleClearForm}
            >
              Clear
            </Button>
          </div>
        </div>
      </form>
    </div>
  );
};

DocManagerFilter.propTypes = {
  onSubmit: PropTypes.func,
  supplierList: PropTypes.array,
  supplierName: PropTypes.string,
  productName: PropTypes.string,
  receivingPointName: PropTypes.string,
  statusName: PropTypes.string,
  setColumnFilter: PropTypes.func,
  setShowFilter: PropTypes.bool,
  receivingPointList: PropTypes.array,
  productList: PropTypes.array,
  statusList: PropTypes.array,
  selectedCountry: PropTypes.string,
  originList: PropTypes.array,
  originName: PropTypes.string,
  setPageIndex: PropTypes.func,
};

export default DocManagerFilter;
