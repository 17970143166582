import {gql} from "@apollo/client";
import {FETCH_POLICY_NETWORK_ONLY} from "constants/config";
import {client} from "providers/Apollo";

export const ALLOCATE_SALE = gql`
  mutation bioLcGlobalMassBalanceAllocate(
    $action: String!
    $createdBy: String!
    $customerName: String!
    $mbInboundQtyEuId: String!
    $outboundType: String!
    $quantity: Float!
    $siteReferenceId: String
    $divisionId: String
  ) {
    bioLcGlobalMassBalanceAllocate(
      event: {
        action: $action
        createdBy: $createdBy
        customerName: $customerName
        mbInboundQtyEuId: $mbInboundQtyEuId
        outboundType: $outboundType
        quantity: $quantity
        siteReferenceId: $siteReferenceId
        divisionId: $divisionId
      }
    ) {
      error
      statusCode
    }
  }
`;

export const AllocateSaleAPI = (data) =>
  client.mutate({
    mutation: ALLOCATE_SALE,
    variables: {
      ...data,
    },
    refetchQueries: [
      "bioLcGetMassBalanceTransactionData",
      "bioLcGetMassBalanceCounts",
    ],
  });

export default {
  ALLOCATE_SALE,
  AllocateSaleAPI,
};

export const GET_SALE_MASTER_DETAILS = gql`
  query getMasterDetailsForSale {
    bioLcGetCertificationSchemeDetails {
      CertificationSchemeDetails {
        validTo
        validFrom
        templateVersionNo
        templateType
        siteReferenceId
        locationId
        locationCode
        documentMasterId
        documentDesc
        documentCode
        divisionId
        certificationSchemeId
        certificateType
      }
    }
    bioLcGetCountryDetails {
      CountryDetailsData {
        code
        countryEnableFlag
        germanName
        name
        originId
        polishName
      }
      statusCode
    }
    bioLcGetProductBaselineEmissionDetails {
      ProductBaselineEmissionDetails {
        baselineEmission
        divisionId
        materialCode
        materialId
        materialShortName
        productBaselineEmissionId
        siteReferenceId
        uomCode
        validFrom
        validStatus
        validTo
      }
    }
    bioLcGetTransportationEmission {
      TransportationEmissionData {
        divisionId
        emissionFactor
        emissionUom
        motName
        receivingLocationId
        receivingLocationCode
        receivingLocationName
        sendingLocationCode
        sendingLocationId
        sendingLocationName
        siteReferenceId
        transportationEmissionId
        validFrom
        validTo
      }
    }
    bioLcGetProductDetails {
      ProductDetailsResponse {
        material_code
        material_group
        material_id
        material_long_name
        material_short_name
        material_status
        material_type
        issc_desc
      }
    }
    bioLcGetBusinessAndLocationDetails {
      businessAndLocationDetails {
        businessPartnerAddress
        businessPartnerCode
        businessPartnerLongName
        businessPartnerId
        businessPartnerShortName
        businessPartnerType
        countryCode
        locationAddress
        locationCode
        locationId
        locationLongName
        locationShortName
        locationType
      }
      error
      statusCode
    }
  }
`;

export const GET_CERTIFICATE_DETAILS = gql`
  query bioLcGetMassBalanceAutoPopulatedData(
    $templateType: String
    $certificationSystem: String
    $vesselName: String
    $dispatchDate: String
    $inboundEuQtyId: String
    $motRecipient: String
    $sdNumber: String
    $issueDate: String
    $siteReferenceId: String
    $divisionId: String
    $businessPartnerCode: String
    $mbLocationGroupId: String
  ) {
    bioLcGetMassBalanceAutoPopulatedData(
      event: {
        templateType: $templateType
        certificationSystem: $certificationSystem
        vesselName: $vesselName
        dispatchDate: $dispatchDate
        inboundEuQtyId: $inboundEuQtyId
        motRecipient: $motRecipient
        sdNumber: $sdNumber
        issueDate: $issueDate
        siteReferenceId: $siteReferenceId
        divisionId: $divisionId
        businessPartnerCode: $businessPartnerCode
        mbLocationGroupId: $mbLocationGroupId
      }
    ) {
      data {
        certificationNumber
        euRedCompliantFlag
        generatedCertificateId
        isSameSupplierShippingAddress
        isccCompliantFlag
        shippingAddress
        supplier
        supplierAddress
        templatePdfUrl
      }
      statusCode
      message
    }
  }
`;

export const getCertificateAutoPopulateDetailsAPI = async (data) => {
  return client.query({
    query: GET_CERTIFICATE_DETAILS,
    variables: {
      ...data,
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: FETCH_POLICY_NETWORK_ONLY,
  });
};

export const ASSIGN_TO_SALE = gql`
  mutation AssignToSale($event: bioLcCPPMassBalanceAssignRequest!) {
    bioLcCPPMassBalanceAssign(event: $event) {
      statusCode
      status
      certificatePdfLink
      message
    }
  }
`;

export const AssignToSaleAPI = (data) =>
  client.mutate({
    mutation: ASSIGN_TO_SALE,
    variables: {
      ...data,
    },
    refetchQueries: [
      "bioLcGetMassBalanceTransactionData",
      "bioLcGetMassBalanceCounts",
    ],
  });
