import PropTypes from "prop-types";

import {COUNTRY_POLAND, COUNTRY_SPAIN} from "constants/countryDetails";
import {uniqBy} from "lodash";
import RadioFlag from "modules/common/BinaryFlag";
import {useEffect, useState} from "react";
import {useFormContext} from "react-hook-form";
import {FormFeedback, FormGroup, Input, Label} from "reactstrap";

import {DIV_CODE_SAF} from "constants/divisionDetails";
import CustomRadioButton from "../../../common/CustomRadioButton";

const SupplimentaryInfo = ({
  mbLocations,
  siteReferenceId,
  mbBalance,
  versioningDocs,
  selectedCountry,
  isDisabled,
  transportEmissions,
  status,
  divisionCode,
}) => {
  const seperationText = "#-#";
  const {
    register,
    formState: {errors},
    watch,
    control,
    getValues,
  } = useFormContext();

  const [showPrevVersion, setShowPrevVersion] = useState(false);
  const emissionForTransport = watch("emissionForTransport");

  const isCompleted = status === "completed";

  useEffect(() => {
    const previousVersionId = getValues("previousVersionId");
    if (previousVersionId) {
      setShowPrevVersion(true);
    }
  }, [getValues]);

  const computeProps = (name, options) => {
    const {ref, ...props} = register(name, options);
    return {innerRef: ref, ...props};
  };

  const uniqueTransportEmissions = uniqBy(transportEmissions ?? [], (v) =>
    [v.sending_location_code, v.receiving_location_code, v.mot_name].join(),
  );

  return (
    <div className="flex flex-col gap-5 w-full">
      {selectedCountry === COUNTRY_SPAIN && (
        <>
          <FormGroup>
            <Label for="emissionForTransport" className="fw-normal">
              Do you need to add emissions for transport?
            </Label>
            <RadioFlag
              type="radio"
              control={control}
              name="emissionForTransport"
              error={errors.emissionForTransport}
              disabled={isDisabled}
            />
          </FormGroup>
          {emissionForTransport && (
            <FormGroup>
              <Label for="transportationEmissionRoute" className="fw-normal">
                Transportation route and type
              </Label>
              <Input
                type="select"
                id="transportationEmissionRoute"
                data-test="transportationEmissionRoute"
                {...computeProps("transportationEmissionRoute", {
                  required: "Please select transportation type and route",
                })}
                invalid={!!errors.transportationEmissionRoute && !isDisabled}
                disabled={isDisabled}
              >
                {uniqueTransportEmissions?.map((transportEmission) => {
                  return (
                    <option
                      key={transportEmission.transportation_emission_id}
                      value={`${transportEmission?.sending_location_code}${seperationText}${transportEmission?.receiving_location_code}${seperationText}${transportEmission?.mot_name}`}
                    >
                      {transportEmission.sending_location_name} -{" "}
                      {transportEmission.receiving_location_name} (
                      {transportEmission.mot_name})
                    </option>
                  );
                })}
              </Input>
            </FormGroup>
          )}
        </>
      )}
      {selectedCountry !== COUNTRY_POLAND && (
        <>
          <FormGroup>
            <Label for="mbLocationGroupId" className="fw-normal mb-4">
              Location
            </Label>
            <Input
              type="select"
              id="mbLocationGroupId"
              {...computeProps("mbLocationGroupId", {
                required: "Please select location",
              })}
              invalid={!!errors.mbLocationGroupId && !isDisabled}
              disabled={isDisabled}
            >
              <option value="">Please select a location</option>
              {mbLocations.map((location) => (
                <option
                  key={location.locationGroupId}
                  value={location.locationGroupId}
                >
                  {location.locationGroupName}
                </option>
              ))}
            </Input>
            {errors.mbLocationGroupId && (
              <FormFeedback className="mt-2">
                {errors.mbLocationGroupId?.message}
              </FormFeedback>
            )}
          </FormGroup>
          <FormGroup>
            <Label for="mbBalanceGroupId" className="fw-normal mb-4">
              Balance
            </Label>
            <Input
              type="select"
              id="mbBalanceGroupId"
              {...computeProps("mbBalanceGroupId", {
                required: "Please select balance",
              })}
              invalid={!!errors.mbBalanceGroupId && !isDisabled}
              disabled={isDisabled}
            >
              <option value="">Please select a balance</option>
              {mbBalance.map((balance) => (
                <option
                  key={balance.balanceGroupId}
                  value={balance.balanceGroupId}
                >
                  {balance.balanceGroupName}
                </option>
              ))}
            </Input>
            {errors.mbBalanceGroupId && (
              <FormFeedback className="mt-2">
                {errors.mbBalanceGroupId?.message}
              </FormFeedback>
            )}
          </FormGroup>
        </>
      )}
      <FormGroup>
        <Label for="replacePreviousVersion" className="fw-normal mb-4">
          Does this document replace any previous version?
        </Label>
        <CustomRadioButton
          type="radio"
          control={control}
          name="replacePreviousVersion"
          error={errors.replacePreviousVersion}
          selectedValue={setShowPrevVersion}
          disabled={status === "rejected"}
        />
      </FormGroup>
      {showPrevVersion && (
        <FormGroup>
          <Label for="previousVersionId" className="fw-normal mb-4">
            Select a previous version
          </Label>
          <Input
            type="select"
            id="previousVersionId"
            data-test="previousVersionId"
            {...computeProps("previousVersionId", {
              required: "Please select a previous version",
            })}
            invalid={!!errors.previousVersionId}
            disabled={status === "rejected"}
          >
            <option value="">Please select a previous version</option>
            {versioningDocs.map((doc) => (
              <option
                key={doc.certificateInboundEuId}
                value={doc.certificateInboundEuId}
              >
                {doc.sdNumber}
              </option>
            ))}
          </Input>
          {errors.previousVersionId && (
            <FormFeedback className="mt-2">
              {errors.previousVersionId?.message}
            </FormFeedback>
          )}
        </FormGroup>
      )}
      {selectedCountry === COUNTRY_SPAIN && (
        <>
          <FormGroup>
            <Label for="invoiceProviderName" className="fw-normal">
              Invoice number
            </Label>
            <Input
              type="text"
              id="invoiceProviderName"
              data-test="invoiceProviderName"
              {...computeProps("invoiceProviderName")}
              invalid={!!errors.invoiceProviderName && !isDisabled}
              disabled={isCompleted}
              maxLength={100}
            />
          </FormGroup>
          <FormGroup>
            <Label for="vesselAtHuelva" className="fw-normal">
              Vessel name
            </Label>
            <Input
              type="text"
              id="vesselAtHuelva"
              data-test="vesselAtHuelva"
              {...computeProps("vesselAtHuelva")}
              invalid={!!errors.vesselAtHuelva && !isDisabled}
              disabled={isCompleted}
              maxLength={50}
            />
          </FormGroup>
        </>
      )}
      {divisionCode !== DIV_CODE_SAF && (
        <FormGroup>
          <Label for="additionalNotes" className="fw-normal mb-4">
            Additional notes (Optional)
          </Label>

          <Input
            type="textarea"
            rows="3"
            id="additionalNotes"
            data-test="additionalNotes"
            {...computeProps("additionalNotes")}
            invalid={!!errors.additionalInfo && !isDisabled}
            maxLength={250}
            disabled={isCompleted}
          />
        </FormGroup>
      )}
    </div>
  );
};

SupplimentaryInfo.propTypes = {
  mbLocations: PropTypes.arrayOf(PropTypes.object),
  versioningDocs: PropTypes.arrayOf(PropTypes.object),
  mbBalance: PropTypes.arrayOf(PropTypes.object),
  siteReferenceId: PropTypes.string,
  status: PropTypes.string,
  selectedCountry: PropTypes.string,
  isDisabled: PropTypes.bool,
  transportEmissions: PropTypes.arrayOf(
    PropTypes.shape({
      transportation_emission_id: PropTypes.number,
      sending_location_name: PropTypes.string,
      receiving_location_name: PropTypes.string,
      receiving_location_code: PropTypes.string,
      mot_name: PropTypes.string,
    }),
  ),
  divisionCode: PropTypes.string,
};
export default SupplimentaryInfo;
