const BioverseSafPowerBi = () => {
  return [
    {
      link: "/saf/dashboard",
      id: "safdashboard",
      title: "Dashboard",
      isVisible: true,
      selectorColor: "bg-yellow-400",
    },
    {
      link: "/powerbireport",
      id: "report",
      title: "Report",
      isVisible: true,
      selectorColor: "bg-yellow-400",
    },
    {
      link: "/powerbiactualization",
      id: "actualisation",
      title: "Actualisation",
      isVisible: true,
      selectorColor: "bg-yellow-400",
    },
  ];
};

export default BioverseSafPowerBi;
