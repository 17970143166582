const generateBreadcrumbItems = (country, safUrls, optionalItems = []) => {
  const commonItems = [
    {text: "BioVerse", link: "/"},
    {
      text: `SAF ${country.charAt(0).toUpperCase() + country.slice(1)}`,
      link: safUrls[`${country}LandingPage`],
    },
  ];

  const allItems = commonItems.concat(optionalItems);

  return allItems.map((item) => {
    return {
      text: item.text,
      link: item.link,
    };
  });
};

// Generate messages for task notification for different types of status
export const generateMessage = (total, MESSAGE) => {
  let message;
  if (total === 0) {
    message = MESSAGE.NO_DOCUMENT;
  } else if (total === 1) {
    message = MESSAGE.ONE_DOCUMENT;
  } else {
    message = `${total} ${MESSAGE.MANY_DOCUMENTS}`;
  }
  return message;
};

export default generateBreadcrumbItems;
