import {RuleStatus} from "modules/compliance-rules-engine/content/RuleStatus";
import getFilterValue from "modules/compliance-rules-engine/utils";
import moment from "moment";
import {Link} from "react-router-dom";
import {UncontrolledTooltip} from "reactstrap";
import ColumnHighlight from "../ColumnHighlight";
import HighLightedText from "../HighLightedText";
import RenderDropdown from "../VersionRenderDropdown";

const renderStatus = (text, cell) => {
  const {className: defaultClassName, message: defaultMessage} =
    RuleStatus.PUBLISHED;
  const {className = defaultClassName, message = defaultMessage} =
    RuleStatus[text] || {};

  return (
    <div className="flex items-center  ">
      <span
        className={`${className} whitespace-nowrap uppercase  status-badge-doc-manager`}
      >
        <HighLightedText
          value={message}
          globalFilter={cell.getContext().table.getState().globalFilter}
        />
      </span>
    </div>
  );
};

const RenderLink = (ruleVersionId, text) => {
  return (
    <Link
      to={{
        pathname: `/compliance-rules-engine/rule-editor/${ruleVersionId}`,
      }}
      className="link-dark"
    >
      <u className="flex flex-row">{`Version - ${text}`}</u>
    </Link>
  );
};

const columnsRules = (handleOpenDeleteModal, handleEditClick, dateFormat) => [
  {
    header: "Created",
    accessorKey: "bvAuditCreatedDatez",
    key: "bvAuditCreatedDatez",
    meta: {
      filterVariant: "daterange",
    },
    filterFn: "dateRangeFilterFn",
    cell: ({cell}) => {
      const dateValue = cell.getValue();
      const formattedDate = dateValue
        ? moment(dateValue).format(dateFormat)
        : "";
      return <HighLightedText value={formattedDate} />;
    },
  },
  {
    header: "Version",
    accessorKey: "version",
    key: "version",
    meta: {
      filterVariant: "search",
    },

    cell: ({row, getValue, table, cell}) => {
      const tooltipId = `version${row.id}`;
      const value = cell.getValue();
      const columnFilter = getFilterValue(cell, "version");

      return (
        <div key={row.id}>
          <Link
            to={{
              pathname: `/compliance-rules-engine/rule-editor/${encodeURIComponent(
                row?.original?.ruleVersionId,
              )}`,
              search: `?pageNo=${table.getState().pagination.pageIndex}`,
            }}
            className="link-dark whitespace-nowrap"
            id={tooltipId}
          >
            <u className="flex flex-row">
              <ColumnHighlight
                value={`Version - ${value}`}
                columnFilter={columnFilter}
                hasEllipsis
                isCertificate
              />
            </u>
          </Link>
          <UncontrolledTooltip placement="bottom" target={tooltipId}>
            Version - {value ?? ""}
          </UncontrolledTooltip>
        </div>
      );
    },
  },

  {
    header: "Valid from",
    accessorKey: "validFrom",
    key: "validFrom",
    meta: {
      filterVariant: "daterange",
    },
    filterFn: "dateRangeFilterFn",
    cell: ({cell}) => {
      const dateValue = cell.getValue();
      const formattedDate = dateValue
        ? moment(dateValue).format(dateFormat)
        : "";
      return <HighLightedText value={formattedDate} />;
    },
  },
  {
    header: "Valid to",
    accessorKey: "validTo",
    key: "validTo",
    meta: {
      filterVariant: "daterange",
    },
    filterFn: "dateRangeFilterFn",
    cell: ({cell}) => {
      const dateValue = cell.getValue();
      const formattedDate = dateValue
        ? moment(dateValue).format(dateFormat)
        : "";
      return <HighLightedText value={formattedDate} />;
    },
  },
  {
    header: "Comment",
    accessorKey: "remarks",
    key: "remarks",
    meta: {
      filterVariant: "search",
    },
    cell: ({cell, row}) => {
      const tooltipId = `ruleName${row?.id}`;
      return (
        <>
          <div className="link-dark" id={tooltipId}>
            <ColumnHighlight
              value={cell.getValue()}
              hasEllipsis
              isCertificate
            />
          </div>
          <UncontrolledTooltip placement="bottom" target={tooltipId}>
            {cell.getValue() ?? ""}
          </UncontrolledTooltip>
        </>
      );
    },
  },
  {
    header: "Status",
    key: "status",
    accessorKey: "status",
    meta: {
      filterVariant: "select",
    },
    cell: ({getValue, cell}) => renderStatus(getValue(), cell),
  },
  {
    header: "",
    accessorKey: "actions",
    key: "actions",
    size: 25,
    visible: true,
    enableSorting: false,
    cell: ({row}) => (
      <RenderDropdown
        onOpenDeleteModal={() =>
          handleOpenDeleteModal(row.original.ruleVersionId)
        }
        status={row.original.status}
        onEditClick={() => handleEditClick(row.original)}
        ruleVersionId={row.original.ruleVersionId}
      />
    ),
  },
];

export default columnsRules;
