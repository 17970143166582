import Icon_Plane_64 from "assets/images/svg/Icon_Plane_64.svg";
import CmosMOTIcon from "assets/images/svg/cmos-icon.svg";
import CsvIcon from "assets/images/svg/csv.svg";
import HomeIcon from "assets/images/svg/home.svg";
import PdfIcon from "assets/images/svg/pdf.svg";
import ShipmentTrackingIcon from "assets/images/svg/shipment-tracking-report.svg";
import * as LABELS from "constants/commonLabels";
import {
  COUNTRY_NORWAY,
  COUNTRY_POLAND,
  COUNTRY_SPAIN,
  COUNTRY_SWEDEN,
  COUNTRY_UK,
} from "constants/countryDetails";
import {saf_urls} from "modules/SAF-dashboard/constants/common";

export const documentsType = [
  {
    link: "/co-processing/upload-documents/invoices",
    img: PdfIcon,
    title: "Invoices",
    description: "Upload invoices for biofeedstocks to Cherry Point here",
  },
  {
    link: "/co-processing/upload-documents/shipment-tracking-reports",
    img: CsvIcon,
    title: "Daily Shipment Tracking Reports",
    description: "Submit Daily Shipment Tracker here",
  },
  {
    link: "/co-processing/upload-documents/bol",
    img: PdfIcon,
    title: "BOL",
    description: "Upload BOLs for biofeedstocks to Cherry Point here",
  },
];

export const posDocTypes = [
  // {
  //   link: "/pos-management/upload-documents/volume-tracker",
  //   img: ExcelIcon,
  //   title: "Upload Volume Tracker",
  //   description: "Upload Volume Tracker here",
  // },
  {
    link: "/pos-management/upload-documents/pos-certificate",
    img: PdfIcon,
    title: "Upload PoS Certificate",
    description: "Upload PoS Certificate here",
  },
];
export const getSAFItems = (country) => {
  if (process.env.REACT_APP_SAF_HIDDEN === "false") {
    switch (country?.toLowerCase()) {
      case "sweden":
        return {
          link: saf_urls.swedenLandingPage,
          id: "bioverse",
          title: "SAF",
          module: "saf",
          moduleTitle: "SAF",
          isVisible: true,
          selectorColor: "bg-bp-green-900",
          subItems: [
            {
              link: saf_urls.swedenLandingPage,
              id: "sweden-landing-page",
              title: "Dashboard",
              module: "saf",
              moduleTitle: "SAF",
              isVisible: true,
              selectorColor: "bg-yellow-400",
            },
            {
              link: saf_urls.swedenDocumentPage,
              id: "bioverse-sweden-document-page",
              title: "Document manager",
              module: "saf",
              moduleTitle: "SAF",
              isVisible: true,
              selectorColor: "bg-yellow-400",
            },
            {
              link: saf_urls.swedenLinkToPurchase,
              id: "bioverse-sweden-link-to-purchase",
              title: "Link to purchase",
              module: "saf",
              moduleTitle: "SAF",
              isVisible: true,
              selectorColor: "bg-yellow-400",
            },
            {
              link: saf_urls.swedenMassBalance,
              id: "bioverse-sweden-mass-balance",
              title: "Mass balance",
              module: "saf",
              moduleTitle: "SAF",
              isVisible: true,
              selectorColor: "bg-yellow-400",
            },
            {
              link: saf_urls.swedenReports,
              id: "bioverse-sweden-reports",
              title: "Reporting",
              module: "saf",
              moduleTitle: "SAF",
              isVisible: true,
              selectorColor: "bg-yellow-400",
            },
          ],
        };
      case "norway":
        return {
          link: saf_urls.norwayLandingPage,
          id: "bioverse-norway-landing-page",
          title: "SAF",
          module: "saf",
          isVisible: true,
          selectorColor: "bg-bp-green-900",
          subItems: [
            {
              link: saf_urls.norwayLandingPage,
              id: "bioverse-co-processing-v2-receipts",
              title: "Dashboard",
              isVisible: true,
              module: "saf",
              moduleTitle: "SAF",
              selectorColor: "bg-yellow-400",
            },
            {
              link: saf_urls.norwayDocumentPage,
              id: "bioverse-norway-document",
              title: "Document manager",
              isVisible: true,
              module: "saf",
              moduleTitle: "SAF",
              selectorColor: "bg-yellow-400",
            },
            {
              link: saf_urls.norwayLinkToPurchase,
              id: "bioverse-norway-link-to-purchase",
              title: "Link to purchase",
              isVisible: true,
              module: "saf",
              moduleTitle: "SAF",
              selectorColor: "bg-yellow-400",
            },
            {
              link: saf_urls.norwayMassBalance,
              id: "bioverse-norway-mass-balance",
              title: "Mass balance",
              isVisible: true,
              module: "saf",
              moduleTitle: "SAF",
              selectorColor: "bg-yellow-400",
            },
            {
              link: saf_urls.norwayReports,
              id: "bioverse-norway-reports",
              title: "Reporting",
              module: "saf",
              moduleTitle: "SAF",
              isVisible: true,
              selectorColor: "bg-yellow-400",
            },
          ],
        };

      default:
        return {
          link: "/feature-country-selection/saf",
          id: "bioverse-co-processing-spain",
          img: ShipmentTrackingIcon,
          title: "SAF",
          module: "saf",
          moduleTitle: "SAF",
          isVisible: true,
          selectorColor: "bg-bp-green-900",
        };
    }
  } else {
    return {
      link: "/saf/dashboard",
      id: "saflandingpage",
      img: Icon_Plane_64,
      module: "saf",
      moduleTitle: "SAF",
      title: "SAF",
      description: LABELS.LANDINGPAGE_ITEM_DESCRIPTION,
      isVisible: true,
      subItems: [
        {
          link: "/saf/dashboard",
          id: "safdashboard",
          title: "Dashboard",
          module: "saf",
          moduleTitle: "SAF",
          isVisible: true,
          selectorColor: "bg-yellow-400",
        },
        {
          link: "/powerbireport",
          id: "report",
          title: "Report",
          module: "saf",
          moduleTitle: "SAF",
          isVisible: true,
          selectorColor: "bg-yellow-400",
        },
        {
          link: "/powerbiactualization",
          id: "actualisation",
          title: "Actualisation",
          module: "saf",
          moduleTitle: "SAF",
          isVisible: true,
          selectorColor: "bg-yellow-400",
        },
      ],
    };
  }
};

export const getDocManagerItems = (country) => {
  switch (country?.toLowerCase()) {
    case "poland":
      return {
        link: `/doc-manager/${encodeURIComponent(COUNTRY_POLAND)}`,
        id: "bioverse-doc-manager-poland",
        img: ShipmentTrackingIcon,
        title: "Document manager",
        module: "documentManager",
        moduleTitle: "Document manager",
        isVisible: true,
        selectorColor: "bg-bp-green-900",
      };
    case "united kingdom":
      return {
        link: `/doc-manager/${encodeURIComponent(COUNTRY_UK)}`,
        id: "bioverse-doc-manager-uk",
        img: ShipmentTrackingIcon,
        title: "Document manager",
        module: "documentManager",
        moduleTitle: "Document manager",
        isVisible: true,
        selectorColor: "bg-bp-green-900",
      };
    case "spain":
      return {
        link: `/doc-manager/${encodeURIComponent(COUNTRY_SPAIN)}`,
        id: "bioverse-doc-manager-spain",
        img: ShipmentTrackingIcon,
        title: "Document manager",
        module: "documentManager",
        moduleTitle: "Document manager",
        isVisible: true,
        selectorColor: "bg-bp-green-900",
      };
    case "norway":
      return {
        link: `/doc-manager/${encodeURIComponent(COUNTRY_NORWAY)}`,
        id: "bioverse-doc-manager-spain",
        img: ShipmentTrackingIcon,
        title: "Document manager",
        module: "documentManager",
        moduleTitle: "Document manager",
        isVisible: true,
        selectorColor: "bg-bp-green-900",
      };
    case "sweden":
      return {
        link: `/doc-manager/${encodeURIComponent(COUNTRY_SWEDEN)}`,
        id: "bioverse-doc-manager-spain",
        img: ShipmentTrackingIcon,
        title: "Document manager",
        module: "documentManager",
        moduleTitle: "Document manager",
        isVisible: true,
        selectorColor: "bg-bp-green-900",
      };
    default:
      return {
        link: "/feature-country-selection/documentManager",
        id: "bioverse-doc-manager",
        img: ShipmentTrackingIcon,
        title: "Document manager",
        module: "documentManager",
        moduleTitle: "Document manager",
        isVisible: true,
        selectorColor: "bg-bp-green-900",
      };
  }
};

export const getCoprocessingItems = (
  country,
  uniqueLocations,
  locationLoading,
) => {
  switch (country?.toLowerCase()) {
    case "spain":
      return {
        link: "/copro-spain/spain/",
        id: "bioverse-co-processing-spain",
        img: ShipmentTrackingIcon,
        title: "Co-processing",
        module: "coProcessing",
        moduleTitle: "Co-processing",
        isVisible: true,
        selectorColor: "bg-bp-green-900",
        subItems: [
          {
            link: "/copro-spain/spain/",
            id: "co-processing-spain-Dashboard",
            title: "Dashboard",
            module: "coProcessing",
            moduleTitle: "Co-processing",
            isVisible: true,
            selectorColor: "bg-yellow-400",
          },
          {
            link: "/copro-spain/spain/document-manager/incoming-documents",
            id: "co-processing-spain-Dashboard-Sustainability-tracking",
            title: "Sustainability tracking",
            isVisible: true,
            selectorColor: "bg-yellow-400",
            subItems: [
              {
                link: "/copro-spain/spain/document-manager/incoming-documents",
                id: "co-processing-spain-Document-manager",
                title: "Document manager",
                isVisible: true,
                module: "coProcessing",
                moduleTitle: "Co-processing",
                selectorColor: "bg-yellow-400",
                subItems: [
                  {
                    link: `/doc-manager/${encodeURIComponent(COUNTRY_SPAIN)}`,
                    id: "co-processing-spain-Document-manager-incoming",
                    title: "Incoming Documents",
                    isVisible: true,
                    module: "coProcessing",
                    moduleTitle: "Co-processing",
                    selectorColor: "bg-yellow-400",
                  },
                  {
                    link: "/copro-spain/spain/document-manager/outgoing-documents",
                    id: "co-processing-spain-Document-manager-outgoing",
                    title: "Outgoing Documents",
                    module: "coProcessing",
                    moduleTitle: "Co-processing",
                    isVisible: true,
                    selectorColor: "bg-yellow-400",
                  },
                ],
              },
              {
                link: "/copro-spain/spain/mass-balance",
                id: "co-processing-spain-Mass-balance",
                title: "Mass Balance",
                module: "coProcessing",
                moduleTitle: "Co-processing",
                isVisible: true,
                selectorColor: "bg-yellow-400",
                subItems: locationLoading
                  ? []
                  : uniqueLocations?.map(({location_name, location_code}) => {
                      return {
                        link:
                          "/copro-spain/spain/mass-balance/" +
                          encodeURIComponent(location_code) +
                          "/" +
                          encodeURIComponent(location_name),
                        id: "copro-spain-mass-balance-" + location_name,
                        title: location_name,
                        isVisible: true,
                        module: "coProcessing",
                        moduleTitle: "Co-processing",
                        selectorColor: "bg-yellow-400",
                      };
                    }),
              },
            ],
          },
          {
            link: "/copro-spain/spain/fifo-feedstock",
            id: "co-processing-spain-Dashboard-Performance-management",
            title: "Performance management",
            isVisible: true,
            module: "coProcessing",
            moduleTitle: "Co-processing",
            selectorColor: "bg-yellow-400",
            subItems: [
              {
                link: "/copro-spain/spain/fifo-feedstock",
                id: "co-processing-spain-Dashboard",
                title: "Feedstock FIFO",
                isVisible: true,
                module: "coProcessing",
                moduleTitle: "Co-processing",
                selectorColor: "bg-yellow-400",
              },
              {
                link: "/copro-spain/spain/cost-calculation",
                id: "co-processing-spain-Dashboard",
                title: "Cost Calculation",
                module: "coProcessing",
                moduleTitle: "Co-processing",
                isVisible: true,
                selectorColor: "bg-yellow-400",
              },
            ],
          },
        ],
      };
    case "united states":
      return {
        link: "/co-processing",
        id: "bioverse-co-processing-v2",
        img: ShipmentTrackingIcon,
        title: "Co-processing",
        module: "coProcessing",
        moduleTitle: "Co-processing",
        isVisible: true,
        selectorColor: "bg-bp-green-900",
        subItems: [
          {
            link: "/co-processing",
            id: "bioverse-co-processing-v2-receipts",
            title: "Receipts",
            module: "coProcessing",
            moduleTitle: "Co-processing",
            isVisible: true,
            selectorColor: "bg-yellow-400",
          },
          {
            link: "/co-processing/batches",
            id: "bioverse-co-processing-v2-batches",
            title: "Batches",
            module: "coProcessing",
            moduleTitle: "Co-processing",
            isVisible: true,
            selectorColor: "bg-yellow-400",
          },
          {
            link: "/co-processing/shipments",
            id: "bioverse-co-processing-v2-shipments",
            title: "Shipments",
            module: "coProcessing",
            moduleTitle: "Co-processing",
            isVisible: true,
            selectorColor: "bg-yellow-400",
          },
          {
            link: "/co-processing/credit-generation",
            id: "bioverse-co-processing-v2-credit-gen",
            title: "Credit Generation",
            module: "coProcessing",
            moduleTitle: "Co-processing",
            isVisible: false,
            selectorColor: "bg-yellow-400",
          },
        ],
      };

    default:
      return {
        link: "/feature-country-selection/coProcessing",
        id: "bioverse-co-processing-spain",
        img: ShipmentTrackingIcon,
        title: "Co-processing",

        module: "coProcessing",
        moduleTitle: "Co-processing",
        isVisible: true,
        selectorColor: "bg-bp-green-900",
      };
  }
};

// Used in Side and tiles
export const Bioverse = (currentCountry, uniqueLocations, locationLoading) => {
  return [
    getCoprocessingItems(currentCountry, uniqueLocations, locationLoading),
    getDocManagerItems(currentCountry),
    {
      link: "/forecasting",
      id: "bioverse-forecasting",
      img: HomeIcon,
      title: "Forecasting",
      module: "forecasting",
      moduleTitle: "Forecasting",

      isVisible: true,
      selectorColor: "bg-bp-green-900",
    },

    {
      link: "/gy-enablement/ceemas-mot-validation/:filterDetails",
      id: "submenu-gy-enablement",
      img: HomeIcon,
      title: "CEEMAS",
      module: "ceemas",
      moduleTitle: "CEEMAS",

      isVisible: true,
      selectorColor: "bg-red-500",
      subItems: [
        {
          link: "/gy-enablement/ceemas-mot-validation/:filterDetails",
          id: "submenu-gy-enablement-report",
          img: CmosMOTIcon,
          title: "CEEMAS",
          module: "ceemas",
          moduleTitle: "CEEMAS",
          isVisible: true,
          selectorColor: "bg-red-500",
        },
        {
          link: "/gy-enablement/mot-manual-adjustment",
          id: "submenu-gy-enablement-manual-adjustment",
          img: CmosMOTIcon,
          title: "MOT Manual Adjustment",
          module: "ceemas",
          moduleTitle: "CEEMAS",
          isVisible: true,
          selectorColor: "bg-red-500",
        },
      ],
    },
    {
      link: "/compliance/monitor",
      id: "compliance-monitor",
      img: HomeIcon,
      title: "Compliance monitor",
      module: "compliance",
      moduleTitle: "Compliance monitor",

      isVisible: true,
      selectorColor: "bg-blue-500",
    },
    {
      link: "/product-metrics",
      id: "bioverse-product-metrics",
      img: HomeIcon,
      title: "Reporting",
      module: "reporting",
      moduleTitle: "Reporting",

      isVisible: true,
      selectorColor: "bg-selector7",
      subItems: [
        {
          link: "/product-metrics",
          id: "product-metrics-feedstock-tracking",
          img: CmosMOTIcon,
          title: "Cherrypoint Feedstock Tracking",
          module: "reporting",
          moduleTitle: "Reporting",
          isVisible: true,
          selectorColor: "bg-red-500",
        },
        {
          link: "/product-metrics/ceemas-mot-validation",
          id: "product-metrics-mot-validation",
          img: CmosMOTIcon,
          title: "CEEMAS",
          module: "reporting",
          moduleTitle: "Reporting",
          isVisible: true,
          selectorColor: "bg-red-500",
        },
        {
          link: "/reporting/bvc-report",
          id: "product-metrics-bvc-report",
          img: CmosMOTIcon,
          title: "BVC Report",
          module: "reporting",
          moduleTitle: "Reporting",
          isVisible: true,
          selectorColor: "bg-red-500",
        },
      ],
    },
    {
      link: "/gy-enablement/master/tax-plant-registry",
      id: "config-center",
      img: HomeIcon,
      title: "Configuration centre",
      module: "configCenter",
      moduleTitle: "Configuration centre",
      isVisible: true,
      selectorColor: "bg-yellow-400",
      subItems: [
        {
          link: "/gy-enablement/master/tax-plant-registry",
          id: "config-center-gnets-tax-plant-registry",
          title: "Tax Plant Registry",
          module: "configCenter",
          moduleTitle: "Configuration centre",
          isVisible: true,
          selectorColor: "bg-yellow-400",
        },
        {
          link: "/gy-enablement/master/NETS-CO2-Costs",
          id: "config-center-gnets-co2-costs",
          title: "NETS CO2 Costs",
          module: "configCenter",
          moduleTitle: "Configuration centre",
          isVisible: true,
          selectorColor: "bg-yellow-400",
        },
        {
          link: "/config-center/coprocessing-spain",
          id: "co-processing-spain-master-settings",
          title: "Co-processing - Spain",
          module: "configCenter",
          moduleTitle: "Configuration centre",
          isVisible: true,
          selectorColor: "bg-yellow-400",
        },
      ],
    },
    getSAFItems(currentCountry),
    // hiding the rules engine for now
    // {
    //   link: "/compliance-rules-engine",
    //   id: "bioverse-rule-engine",
    //   img: HomeIcon,
    //   title: "Rules engine",
    //   module: "reporting",
    //   moduleTitle: "reporting",

    //   isVisible: true,
    //   selectorColor: "bg-bp-green-900",
    // },
  ];
};

export default {documentsType};
