import * as yup from "yup";

export const GenerateCertificateFormSchema = yup
  .object()
  .shape({
    dateOfIssuance: yup.string().label("Date of issuance").required(),
    modeOfTransportation: yup
      .string()
      .label("Mode of transportation")
      .required(),
    contractNumber: yup.string().label("Contract number").required(),
  })
  .required();

export default GenerateCertificateFormSchema;
