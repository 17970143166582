import {FilePdf24} from "@bphxd/ds-core-react/lib/icons";
import {isEmpty} from "lodash";
import {formatNumber, getFilterValue} from "modules/SAF-dashboard/utils";
import moment from "moment";
import {Link, useParams} from "react-router-dom";
import ColumnHighlight from "./ColumnHighlight";

export const INCOMING_COLUMNS = (
  dateFormat,
  site_reference_id,
  division_id,
) => [
  {
    header: "Incoming document",
    id: "incoming_document_header",
    columns: [
      {
        header: "Document",
        accessorKey: "sd_number",
        key: "sd_number",
        visible: true,
        disabled: true,
        size: 200,
        cell: ({cell, row}) => {
          const {country} = useParams();
          const locationDocKey = country + "DocumentPage";
          const certi_id = row?.original?.certificate_inbound_eu_id;
          const DetailDocURL = `/document-manager/incoming/spain/detailedView/${certi_id}?pageNo=0&siteReferenceId=${site_reference_id}&divisionId=${division_id}`;

          return (
            <Link to={DetailDocURL} className="link-dark">
              <u className="flex">
                {cell.getValue() && <FilePdf24 />}
                <ColumnHighlight
                  value={cell.getValue()}
                  columnFilter={getFilterValue(cell, "certificate_number")}
                  hasEllipsis
                  isCertificate
                />
              </u>
            </Link>
          );
        },
      },
      {
        header: "Dispatch date",
        key: "material_dispatch_datez",
        accessorKey: "material_dispatch_datez",
        filterFn: "dateRangeFilterFn",
        visible: true,
        size: 200,
        meta: {
          filterVariant: "daterange",
        },
        cell: ({cell}) => {
          return isEmpty(cell.getValue())
            ? ""
            : moment(cell.getValue()).format(dateFormat);
        },
      },
      {
        header: "Balance",
        accessorKey: "balance_group_name",
        key: "balance_group_name",
        size: 200,
        visible: true,
        meta: {
          filterVariant: "select",
        },
      },
      {
        header: "Supplier",
        accessorKey: "supplier_name",
        key: "supplier_name",
        visible: true,
        meta: {
          filterVariant: "select",
        },
      },
      {
        header: "Location",
        accessorKey: "location_group_name",
        key: "location_group_name",
        size: 200,
        visible: true,
        meta: {
          filterVariant: "select",
        },
      },
      {
        header: "Quantity MT",
        accessorKey: "product_qty",
        key: "product_qty",
        size: 200,
        visible: true,
        dataType: "number",
        meta: {
          filterVariant: "range",
        },
        cell: ({cell}) => {
          return cell.getValue() ? formatNumber(cell.getValue(), "", 3) : "";
        },
      },
      {
        header: "Contract number",
        accessorKey: "contract_number",
        key: "contract_number",
        visible: false,
        size: 50,
        cell: ({cell}) => {
          return (
            <ColumnHighlight
              value={cell.getValue()}
              columnFilter={getFilterValue(cell, "contract_number")}
            />
          );
        },
      },
      {
        header: "Dispatch address",
        accessorKey: "supplier_dispatch_address",
        key: "supplier_dispatch_address",
        footer: (props) => props.column.id,
        visible: false,
        meta: {
          filterVariant: "select",
        },
      },
      {
        header: "Product",
        accessorKey: "product_type",
        key: "product_type",
        visible: false,
        meta: {
          filterVariant: "select",
        },
      },
      {
        header: "Raw material",
        accessorKey: "raw_material",
        key: "raw_material",
        visible: false,
        meta: {
          filterVariant: "select",
        },
      },
      {
        header: "Country of origin",
        accessorKey: "country_of_origin",
        key: "country_of_origin",
        visible: false,
        meta: {
          filterVariant: "select",
        },
      },
      {
        header: "Receiving point",
        accessorKey: "recipient_address",
        key: "recipient_address",
        visible: false,
        meta: {
          filterVariant: "select",
        },
      },
      {
        header: "Quantity m³",
        accessorKey: "product_qty_m3",
        key: "product_qty_m3",
        size: 200,
        visible: false,
        dataType: "number",
        meta: {
          filterVariant: "range",
        },
        cell: ({cell}) => {
          return cell.getValue() ? formatNumber(cell.getValue(), "", 3) : "";
        },
      },
      {
        header: "Energy MJ",
        accessorKey: "energy_mj_incoming",
        key: "energy_mj_incoming",
        visible: false,
        dataType: "number",
        meta: {
          filterVariant: "range",
        },
        cell: ({cell}) => {
          return cell.getValue() ? formatNumber(cell.getValue(), "", 3) : "";
        },
      },
      {
        header: "GHG total gCO2eq/MJ",
        accessorKey: "ghg_gco2eq_incoming",
        accessorFn: (row) => {
          return row.ghg_gco2eq_incoming / 1000;
        },
        key: "ghg_gco2eq_incoming",
        visible: false,
        dataType: "number",
        meta: {
          filterVariant: "range",
        },
        cell: ({cell}) => {
          return cell.getValue() ? formatNumber(cell.getValue(), "", 3) : "";
        },
      },
    ],
  },
];

export const PURCHASE_COLUMNS = (dateFormat) => [
  {
    header: "Purchase",
    columns: [
      {
        header: "Purchase order",
        accessorKey: "po_number",
        key: "po_number",
        visible: true,
        disabled: true,
        cell: ({cell}) => {
          return (
            <ColumnHighlight
              value={cell.getValue()}
              columnFilter={getFilterValue(cell, "po_number")}
            />
          );
        },
      },
      {
        header: "Purchase date",
        key: "purchase_date",
        accessorKey: "purchase_date",
        filterFn: "dateRangeFilterFn",
        visible: true,
        meta: {
          filterVariant: "daterange",
        },
        cell: ({cell}) => {
          return isEmpty(cell.getValue())
            ? ""
            : moment(cell.getValue()).format(dateFormat);
        },
      },
      {
        header: "Physical receipt date",
        key: "discharge_date",
        accessorKey: "discharge_date",
        filterFn: "dateRangeFilterFn",
        visible: true,
        meta: {
          filterVariant: "daterange",
        },
        cell: ({cell}) => {
          return isEmpty(cell.getValue())
            ? ""
            : moment(cell.getValue()).format(dateFormat);
        },
      },
      {
        header: "Supplier",
        accessorKey: "vendor_name",
        key: "vendor_name",
        visible: true,
        meta: {
          filterVariant: "select",
        },
      },
      {
        header: "Location",
        accessorKey: "location_group_name",
        key: "location_group_name",
        size: 200,
        visible: true,
        meta: {
          filterVariant: "select",
        },
      },
      {
        header: "Received quantity MT",
        accessorKey: "received_qty_mt",
        key: "received_qty_mt",
        size: 100,
        visible: true,
        dataType: "number",
        meta: {
          filterVariant: "range",
        },
        cell: ({cell}) => {
          return cell.getValue() ? formatNumber(cell.getValue(), "", 3) : "";
        },
      },
      {
        header: "Purchase quantity MT",
        accessorKey: "purchase_qty_mt",
        key: "purchase_qty_mt",
        size: 100,
        visible: true,
        dataType: "number",
        meta: {
          filterVariant: "range",
        },
        cell: ({cell}) => {
          return cell.getValue() ? formatNumber(cell.getValue(), "", 3) : "";
        },
      },
      {
        header: "Deal number",
        accessorKey: "deal_number",
        key: "deal_number",
        visible: false,
        cell: ({cell}) => {
          return (
            <ColumnHighlight
              value={cell.getValue()}
              columnFilter={getFilterValue(cell, "contract_number")}
            />
          );
        },
      },
      {
        header: "Deal date",
        key: "deal_done_date",
        accessorKey: "deal_done_date",
        filterFn: "dateRangeFilterFn",
        visible: false,
        meta: {
          filterVariant: "daterange",
        },
        cell: ({cell}) => {
          return isEmpty(cell.getValue())
            ? ""
            : moment(cell.getValue()).format(dateFormat);
        },
      },
      {
        header: "Invoice number",
        accessorKey: "invoice_number",
        key: "invoice_number",
        visible: false,
        cell: ({cell}) => {
          return (
            <ColumnHighlight
              value={cell.getValue()}
              columnFilter={getFilterValue(cell, "invoice_number")}
            />
          );
        },
      },
      {
        header: "Material number",
        accessorKey: "material_number",
        key: "material_number",
        visible: false,
        meta: {
          filterVariant: "select",
        },
      },
      {
        header: "Raw material",
        accessorKey: "material_description",
        key: "material_description",
        visible: false,
        meta: {
          filterVariant: "select",
        },
      },
      {
        header: "Shipping point",
        accessorKey: "source_location_name",
        key: "source_location_name",
        visible: false,
        meta: {
          filterVariant: "select",
        },
      },
      {
        header: "Receiving point",
        accessorKey: "destination_location_name",
        key: "destination_location_name",
        visible: false,
        meta: {
          filterVariant: "select",
        },
      },
      {
        header: "Discharge plant code",
        accessorKey: "discharge_plant_code",
        key: "discharge_plant_code",
        visible: false,
        meta: {
          filterVariant: "select",
        },
      },
      {
        header: "Purchase plant code",
        accessorKey: "purchase_plant_code",
        key: "purchase_plant_code",
        visible: false,
        meta: {
          filterVariant: "select",
        },
      },
      {
        header: "Received quantity m³",
        accessorKey: "received_qty_m3",
        key: "received_qty_m3",
        size: 100,
        visible: false,
        dataType: "number",
        meta: {
          filterVariant: "range",
        },
        cell: ({cell}) => {
          return cell.getValue() ? formatNumber(cell.getValue(), "", 3) : "";
        },
      },
      {
        header: "Actualized quantity m³",
        accessorKey: "quantity_actual_m3",
        key: "quantity_actual_m3",
        size: 100,
        visible: false,
        dataType: "number",
        meta: {
          filterVariant: "range",
        },
        cell: ({cell}) => {
          return cell.getValue() ? formatNumber(cell.getValue(), "", 3) : "";
        },
      },
      {
        header: "Quantity m³",
        accessorKey: "purchase_qty_m3",
        key: "purchase_qty_m3",
        size: 200,
        visible: false,
        dataType: "number",
        meta: {
          filterVariant: "range",
        },
        cell: ({cell}) => {
          return cell.getValue() ? formatNumber(cell.getValue(), "", 3) : "";
        },
      },
      {
        header: "Actualized quantity MT",
        accessorKey: "quantity_actual_mt",
        key: "quantity_actual_mt",
        size: 100,
        visible: false,
        dataType: "number",
        meta: {
          filterVariant: "range",
        },
        cell: ({cell}) => {
          return cell.getValue() ? formatNumber(cell.getValue(), "", 3) : "";
        },
      },
      {
        header: "Energy MJ",
        accessorKey: "energy_mj_purchase",
        key: "energy_mj_purchase",
        visible: false,
        dataType: "number",
        meta: {
          filterVariant: "range",
        },
        cell: ({cell}) => {
          return cell.getValue() ? formatNumber(cell.getValue(), "", 3) : "";
        },
      },
      {
        header: "GHG total kgCO2eq",
        accessorKey: "ghg_gco2eq_purchase",
        accessorFn: (row) => {
          return row.ghg_gco2eq_incoming / 1000;
        },
        key: "ghg_gco2eq_purchase",
        visible: false,
        dataType: "number",
        meta: {
          filterVariant: "range",
        },
        cell: ({cell}) => {
          return cell.getValue() ? formatNumber(cell.getValue(), "", 3) : "";
        },
      },
      {
        header: "GHG total gCO2eq/MJ",
        accessorKey: "ghg_gco2eq_mj",
        key: "ghg_gco2eq_mj",
        visible: false,
        dataType: "number",
        meta: {
          filterVariant: "range",
        },
        cell: ({cell}) => {
          return cell.getValue() ? formatNumber(cell.getValue(), "", 3) : "";
        },
      },
      {
        header: "Balance",
        accessorKey: "balance_group_name",
        key: "balance_group_name",
        visible: false,
        meta: {
          filterVariant: "select",
        },
      },
    ],
  },
];

export default INCOMING_COLUMNS;
