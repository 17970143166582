import {useAccount, useMsal} from "@azure/msal-react";
import {Kebab24} from "@bphxd/ds-core-react/lib/icons";
import PropTypes from "prop-types";
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap";

const RenderDropdown = ({
  ruleMasterId,
  handleEditClick,
  countryCode,
  ruleName,
  userId,
  setDeleteModal,
  setModalContent,
}) => {
  const {accounts} = useMsal();
  const account = useAccount(accounts[0]);

  const handleDeleteClick = () => {
    setModalContent({
      ruleMasterId,
      countryCode,
      ruleName,
      userId: account?.username,
    });
    setDeleteModal(true);
  };

  return (
    <UncontrolledDropdown
      direction="down"
      className="compliance-action-dropdown"
    >
      <DropdownToggle
        color="standard-quartenary"
        className="!px-0 compliance-action-dropdown-button"
      >
        <Kebab24 />
      </DropdownToggle>
      <DropdownMenu>
        <DropdownItem
          onClick={() => handleEditClick(ruleName, countryCode, ruleMasterId)}
        >
          Edit details
        </DropdownItem>
        <DropdownItem onClick={handleDeleteClick}>Delete</DropdownItem>
      </DropdownMenu>
    </UncontrolledDropdown>
  );
};

RenderDropdown.propTypes = {
  ruleMasterId: PropTypes.string,
  countryCode: PropTypes.string,
  ruleName: PropTypes.string,
  userId: PropTypes.string,
  handleEditClick: PropTypes.func.isRequired,
  setDeleteModal: PropTypes.func.isRequired,
  setModalContent: PropTypes.func.isRequired,
};

export default RenderDropdown;
