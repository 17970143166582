import {useMutation, useQuery} from "@apollo/client";
import {useAccount, useMsal} from "@azure/msal-react";
import {Add24, Filter24} from "@bphxd/ds-core-react/lib/icons";
import {
  COMPLIANCE_RULES_ADD_RULE,
  COMPLIANCE_RULES_EDIT_RULE,
  COMPLIANCE_RULES_LANDING_LIST_DISPLAY,
  DELETE_RULE,
  POPULATE_COUNTRY_DROPDOWN,
} from "graphql/compliance-rules-engine/RulesLandingPageAPI";
import {ADD_RULE_VERSION} from "graphql/compliance-rules-engine/VersionsPageAPI";
import moment from "moment";
import {useAppSetting} from "providers/appSetting";
import {useUserSettings} from "providers/userSettings";
import React, {useState} from "react";
import {FormProvider, useForm} from "react-hook-form";
import {useNavigate, useParams} from "react-router-dom";
import {toast} from "react-toastify";
import {Button} from "reactstrap";
import tw from "twin.macro";
import columnsMain from "../components/columns/ColumnRules";
import Layout from "../components/index";
import ComplianceDocumentTable from "../components/tables/ComplianceDocumentTable";
import DeleteRuleModal from "../modals/DeleteModal";
import EditRuleModal from "../modals/EditModal";
import RuleModalDisplay from "../modals/RuleModal";

const breadcrumbItems = [
  {text: "BioVerse", link: "/"},
  {text: "Compliance rules engine", link: "/compliance-rules-engine"},
];

const ComplianceRulesLandingPage = () => {
  const {accounts} = useMsal();
  const account = useAccount(accounts[0]);
  const {
    userSettings: {dateFormat},
  } = useUserSettings();
  const methods = useForm();
  const [showFilter, setShowFilter] = useState(false);
  const [columnFilter, setColumnFilter] = useState([]);
  const [showUploadModal, setUploadModal] = useState(false);
  const [showEditModal, setEditModal] = useState(false);
  const [showDeleteModal, setDeleteModal] = useState(false);
  const [modalContent, setModalContent] = useState({
    title: "",
    inputIndex: "",
    dropdownIndex: "",
  });
  const [initialData, setInitialData] = useState({});
  const {appSetting} = useAppSetting();

  const navigate = useNavigate();

  const {
    data: complianceRecordsData,
    loading,
    refetch,
  } = useQuery(COMPLIANCE_RULES_LANDING_LIST_DISPLAY, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "network-only",
    variables: {
      countryCode:
        appSetting?.currentCountryCode === "GO"
          ? "ALL"
          : appSetting?.currentCountryCode,
    },
    skip: appSetting?.currentCountryCode === undefined,
  });

  const {
    data: countryData,
    loading: countryLoading,
    error: countryError,
  } = useQuery(POPULATE_COUNTRY_DROPDOWN);

  const complianceResponseData =
    complianceRecordsData?.bioLcComplianceRulesEngineGetRules?.rules;

  const [addVersion] = useMutation(ADD_RULE_VERSION, {
    onCompleted: ({bioLcComplianceRulesEngineCreateUpdateVersion}) => {
      if (bioLcComplianceRulesEngineCreateUpdateVersion?.statusCode === 201) {
        toast.success("Rule & version created successfully", {
          autoClose: 750,
        });
        setUploadModal(false);

        navigate(
          `/compliance-rules-engine/rule-editor/${bioLcComplianceRulesEngineCreateUpdateVersion?.ruleVersionId}`,
        );
      } else {
        toast.error(
          bioLcComplianceRulesEngineCreateUpdateVersion?.error ??
            "Failed to create version, please try again",
          {
            autoClose: 750,
          },
        );
      }
    },
  });

  const [addRule] = useMutation(COMPLIANCE_RULES_ADD_RULE, {
    onCompleted: ({bioLcComplianceRulesEngineCreateUpdateRule}) => {
      if (bioLcComplianceRulesEngineCreateUpdateRule?.statusCode === 201) {
        setUploadModal(false);
        refetch();
        // will remove this once the rule editor is ready
        // navigate(
        //   `/compliance-rules-engine/versions/${bioLcComplianceRulesEngineCreateUpdateRule?.ruleMasterId}`,
        // );
        addVersion({
          variables: {
            event: {
              isPublishActionConfirmed: false,
              operation: "create",
              remarks: "",
              ruleMasterId:
                bioLcComplianceRulesEngineCreateUpdateRule?.ruleMasterId,
              userId: account?.username,
              validFrom: moment(new Date()).format("YYYY-MM-DD"),
            },
          },
        });
      } else if (
        bioLcComplianceRulesEngineCreateUpdateRule?.errors?.length > 0
      ) {
        toast.error("An issue occurred while creating the rule.", {
          autoClose: 750,
        });
      }
    },
  });

  const [editRule] = useMutation(COMPLIANCE_RULES_EDIT_RULE, {
    onCompleted: ({bioLcComplianceRulesEngineCreateUpdateRule}) => {
      if (bioLcComplianceRulesEngineCreateUpdateRule?.statusCode === 204) {
        toast.success("Rule updated successfully", {
          autoClose: 750,
        });
        setEditModal(false);
        refetch();
      } else if (
        bioLcComplianceRulesEngineCreateUpdateRule?.errors?.length > 0
      ) {
        toast.error("An issue occurred while updating the rule.", {
          autoClose: 750,
        });
      }
    },
  });

  const [deleteRule, {loading: deleteLoading, error: deleteError}] =
    useMutation(DELETE_RULE, {
      onCompleted: ({bioLcComplianceRulesEngineCreateUpdateRule}) => {
        const {statusCode} = bioLcComplianceRulesEngineCreateUpdateRule;

        if (statusCode === 204) {
          toast.success("Rule deleted successfully", {
            autoClose: 750,
          });
          refetch();
        }
      },
    });

  const handleDeleteRule = async (
    ruleMasterId,
    countryCode,
    ruleName,
    userId,
  ) => {
    console.log("Deleting rule with variables:", {
      ruleMasterId,
      countryCode,
      ruleName,
      userId: account?.username,
    });
    try {
      const response = await deleteRule({
        variables: {
          ruleMasterId,
          countryCode,
          ruleName,
          userId: account?.username,
        },
      });

      return response;
    } catch (error) {
      return {errors: [error]};
    }
  };

  const handleEditClick = (name, country, ruleMasterId) => {
    setInitialData({
      name,
      country,
      ruleMasterId,
    });
    setModalContent({
      title: "Edit Rule",
      inputIndex: "Rule Name",
      dropdownIndex: "Country",
    });
    setEditModal(true);
  };

  const handleEditRuleSubmit = (ruleData) => {
    editRule({
      variables: {
        countryCode: ruleData?.dropdownValue,
        operation: "update",
        ruleName: ruleData.inputValue,
        userId: account?.username,
        ruleMasterId: initialData?.ruleMasterId,
      },
    });
  };

  const {country} = useParams();
  const handleAddRuleClick = (ruleMasterId, text, pageNo) => {
    setModalContent({
      title: "New rule",
      inputIndex: "Rule name",
      dropdownIndex: "Country",
    });
    setUploadModal(true);
  };

  const handleAddRuleSubmit = (ruleData) => {
    addRule({
      variables: {
        event: {
          countryCode: ruleData?.dropdownValue,
          operation: "create",
          ruleName: ruleData.inputValue,
          userId: account?.username,
        },
      },
    });
  };

  const handleDelete = async () => {
    const response = await handleDeleteRule(
      modalContent.ruleMasterId,
      modalContent.countryCode,
      modalContent.ruleName,
      modalContent.userId,
    );
    if (
      response.errors ||
      response.data.bioLcComplianceRulesEngineCreateUpdateRule.statusCode ===
        400
    ) {
      setDeleteModal(true);
      setModalContent((prev) => ({
        ...prev,
        errorMessage: `Rule ID: ${modalContent.ruleMasterId} has version = 400. Deletion not allowed.`,
      }));
    } else {
      setDeleteModal(false);
    }
  };

  return (
    <Layout title="Rules" breadcrumbs={breadcrumbItems}>
      <div className="flex flex-col justify-stretch w-full items-end">
        <div className="flex flex-row justify-end w-full my-5 px-7 gap-x-3 items-center">
          <div className="mr-2">
            <Button
              data-test="doc-manager-filter-btn"
              color="standard-tertiary rounded-0"
              onClick={() => setShowFilter(!showFilter)}
            >
              Filters
              <Filter24 className="btn-icon-suffix" />
            </Button>
          </div>
          <div className="">
            <Button
              color="primary"
              type="secondary"
              className="float-right rounded-0 text-gray-400"
              id="addRuleButton"
              size="md"
              onClick={handleAddRuleClick}
            >
              Add new rule
              <Add24 className="btn-icon-suffix" />
            </Button>
          </div>
        </div>
        <div className="border-secondary w-full px-7 min-h-[500px]">
          <ComplianceDocumentTable
            columns={columnsMain(
              handleEditClick,
              handleDeleteRule,
              dateFormat,
              setDeleteModal,
              setModalContent,
            )}
            data={complianceResponseData ?? []}
            showFilter={showFilter}
            columnFilter={columnFilter}
            loading={loading}
            setColumnFilter={setColumnFilter}
          />
        </div>
      </div>
      <FormProvider {...methods}>
        {showUploadModal && (
          <RuleModalDisplay
            showUploadModal={showUploadModal}
            setUploadModal={setUploadModal}
            titleHead={modalContent.title}
            inputIndex={modalContent.inputIndex}
            dropdownIndex={modalContent.dropdownIndex}
            setInputIndex="Enter rulename"
            setDropdownIndex={modalContent.setDropdownIndex}
            onSubmit={handleAddRuleSubmit}
            countryCode={appSetting?.currentCountryCode}
          />
        )}
      </FormProvider>
      {showEditModal && (
        <EditRuleModal
          showEditModal={showEditModal}
          setEditModal={setEditModal}
          titleHead={modalContent.title}
          inputIndex={modalContent.inputIndex}
          setInputIndex="Enter name"
          dropdownIndex={modalContent.dropdownIndex}
          setDropdownIndex="Select country"
          onSubmit={handleEditRuleSubmit}
          initialData={initialData}
          countryCode={appSetting?.currentCountryCode}
        />
      )}
      {showDeleteModal && (
        <DeleteRuleModal
          showDeleteModal={showDeleteModal}
          setDeleteModal={setDeleteModal}
          onDelete={handleDelete}
          errorMessage={modalContent.errorMessage}
        />
      )}
    </Layout>
  );
};

export default ComplianceRulesLandingPage;

const HeaderSection = tw.div`relative flex flex-col pb-5 mx-[40px]`;
const PageTitle = tw.h1`text-[1.8125rem] -mb-5`;
