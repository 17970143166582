import {useQuery} from "@apollo/client";
import {Close} from "@bphxd/ds-core-react";
import {
  COMPLIANCE_RULES_LANDING_LIST_DISPLAY,
  POPULATE_COUNTRY_DROPDOWN,
} from "graphql/compliance-rules-engine/RulesLandingPageAPI";
import PropTypes from "prop-types";
import {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {
  Button,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import {countries} from "../components/countryList";
import FormDropdownWithIcon from "../components/FormDropdown/FormDropdownWithIcon";

const EditRuleModal = ({
  showEditModal,
  setEditModal,
  titleHead,
  inputIndex,
  setInputIndex,
  dropdownIndex,
  setDropdownIndex,
  onSubmit,
  initialData,
}) => {
  const {country: navCountry} = useParams();
  const [inputValue, setInputValue] = useState(initialData.name || "");
  const [dropdownValue, setDropdownValue] = useState(
    initialData.country || null,
  );

  const {
    data: countryData,
    loading: countryLoading,
    error: countryError,
  } = useQuery(POPULATE_COUNTRY_DROPDOWN);

  const {
    data: existingRulesData,
    loading: rulesLoading,
    error: rulesError,
  } = useQuery(COMPLIANCE_RULES_LANDING_LIST_DISPLAY, {
    variables: {countryCode: dropdownValue?.value},
  });

  useEffect(() => {
    setInputValue(initialData.name);
    if (countryData) {
      const initialCountry = countryData.bioLcGlobNaviGetModuleCatalogue.find(
        (country) => country.countryCode === initialData.country,
      );
      setDropdownValue(
        initialCountry
          ? {
              value: initialCountry.countryCode,
              label: initialCountry.countryName,
            }
          : null,
      );
    }
  }, [countryData, initialData]);

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    onSubmit({inputValue, dropdownValue: dropdownValue.value});
    setEditModal(false);
  };

  const countryOptions = countryData
    ? countryData.bioLcGlobNaviGetModuleCatalogue.map((country) => ({
        value: country.countryCode,
        label: country.countryName,
      }))
    : [];

  const filteredCountryOptions = countryOptions.filter(
    (country) =>
      country.label.toLowerCase() !== "global" && country.value !== "GO",
  );

  return (
    <div>
      <Modal
        isOpen={showEditModal}
        className="modal-dialog-centered"
        style={{width: "351px"}}
      >
        <ModalHeader
          className="!border-b-[1px] !border-gray-200 text-lg"
          close={
            <Close
              onClick={() => {
                setEditModal(false);
              }}
            />
          }
        >
          {titleHead}
        </ModalHeader>
        <ModalBody className="py-0">
          <form id="edit-form" onSubmit={handleFormSubmit}>
            <div className="w-full flex flex-col gap-7">
              <span className="text-sm"></span>

              <FormGroup>
                <Label for="ruleName" className="fw-medium text-sm">
                  {inputIndex}
                </Label>
                <Input
                  type="input"
                  placeholder={setInputIndex}
                  id="ruleName"
                  value={inputValue}
                  onChange={(e) => setInputValue(e.target.value)}
                />
              </FormGroup>

              <FormGroup>
                <Label for="country" className="fw-medium text-sm">
                  {dropdownIndex}
                </Label>
                <div className="country-dropdown">
                  <FormDropdownWithIcon
                    options={filteredCountryOptions.map((country) =>
                      country.label.toLowerCase(),
                    )}
                    values={filteredCountryOptions.map(
                      (country) => country.value,
                    )}
                    icons={filteredCountryOptions.map(
                      (country) =>
                        countries?.find(
                          (x) =>
                            x.countryName?.toLowerCase() ===
                            country.label.toLowerCase(),
                        )?.img,
                    )}
                    data-test="periodYear"
                    placeholder="Select country"
                    menuClassName="country-dropdown-filter w-full"
                    value={dropdownValue}
                    onChange={(selectedOption) =>
                      setDropdownValue(selectedOption)
                    }
                  />
                </div>
              </FormGroup>
              <ModalFooter className="p-0 d-block mt-5">
                <div className="row g-0 m-0 modal-footer-row">
                  <div className="col-6 d-grid">
                    <Button
                      color="darker-tertiary"
                      size="lg"
                      className="border-0 rounded-0 py-4 opacity-80 opacity-100-hover bg-transparent"
                      onClick={() => {
                        setEditModal(false);
                      }}
                    >
                      Cancel
                    </Button>
                  </div>
                  <div className="col-6 d-grid">
                    <Button
                      color="darker-tertiary"
                      size="lg"
                      className="border-0 rounded-0 py-4 bg-transparent text-default"
                      type="submit"
                      disabled={rulesLoading || countryLoading}
                    >
                      Save
                    </Button>
                  </div>
                </div>
              </ModalFooter>
            </div>
          </form>
        </ModalBody>
      </Modal>
    </div>
  );
};

EditRuleModal.propTypes = {
  showEditModal: PropTypes.bool,
  setEditModal: PropTypes.func,
  titleHead: PropTypes.string,
  inputIndex: PropTypes.string,
  dropdownIndex: PropTypes.string,
  setInputIndex: PropTypes.func,
  setDropdownIndex: PropTypes.func,
  onSubmit: PropTypes.func,
  initialData: PropTypes.object,
};

export default EditRuleModal;
