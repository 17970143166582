import PropTypes from "prop-types";
import React from "react";
import {Button, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";

const DeleteRuleModal = ({
  showDeleteModal,
  setDeleteModal,
  onDelete,
  errorMessage,
}) => {
  return (
    <Modal
      isOpen={showDeleteModal}
      toggle={() => setDeleteModal(false)}
      className="modal-dialog-centered"
      style={{width: "296px", height: "140px"}}
    >
      <ModalHeader
        className="justify-content-center pb-0 font-medium color-[#111111eb]"
        style={{fontSize: "20px", textAlign: "center"}}
      ></ModalHeader>
      <ModalBody
        className="text-center"
        style={{padding: "20px", fontSize: "16px"}}
      >
        {errorMessage ? (
          <>
            <div className="opacity-100 bg-transparent pb-2">
              Rules cannot be deleted
            </div>
            <p
              className="small opacity-80 lh-1-375 mx-4 mb-0 font-[#11111191
] flex flex-wrap"
              style={{whiteSpace: "pre-line"}}
            >
              The rule was a &apos;published&apos; rule version and cannot be
              deleted.
            </p>
          </>
        ) : (
          "Are you sure you want to delete this rule?"
        )}
      </ModalBody>
      <ModalFooter className="p-0">
        <div className="w-100">
          <div className="font-bold flex justify-evenly">
            <Button
              color="darker-tertiary"
              size="lg"
              className="border-0 rounded-0 py-3 opacity-60 opacity-100-hover bg-transparent"
              onClick={() => setDeleteModal(false)}
              style={{
                color: "#000",
                backgroundColor: "#f8f9fa",
                borderColor: "#f8f9fa",
              }}
            >
              {errorMessage ? "Return to Rules" : "Cancel"}
            </Button>
            {!errorMessage && (
              <Button
                color="danger"
                size="lg"
                className="border-0 rounded-0 py-3 opacity-60 opacity-100-hover bg-transparent"
                onClick={onDelete}
              >
                Delete
              </Button>
            )}
          </div>
        </div>
      </ModalFooter>
    </Modal>
  );
};

DeleteRuleModal.propTypes = {
  showDeleteModal: PropTypes.bool.isRequired,
  setDeleteModal: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  errorMessage: PropTypes.string,
};

export default DeleteRuleModal;
