export const MB_BALANCE_GROUP_COLUMNS = [
  {
    header: "Balance group name",
    accessorKey: "mbBalanceGroupName",
    key: "mbBalanceGroupName",
  },
];

export const MB_BALANCE_TYPE_COLUMNS = [
  {
    header: "Mass balance type name",
    accessorKey: "mbBalanceTypeName",
    key: "mbBalanceTypeName",
  },
];

export const MATERIAL_MASTER_COLUMNS = [
  {
    header: "Material short name",
    accessorKey: "materialShortName",
    key: "materialShortName",
  },
  {
    header: "Material long name",
    accessorKey: "materialLongName",
    key: "materialLongName",
  },
  {
    header: "Material type",
    accessorKey: "materialType",
    key: "materialType",
  },
  {
    header: "Accepted by",
    accessorKey: "acceptedByCertificationSchemes",
    key: "acceptedByCertificationSchemes",
    cell: (cell) => {
      const values = cell.getValue();
      return values ? values.join(", ") : "";
    },
  },
  {
    header: "ISSC description",
    accessorKey: "isscDesc",
    key: "isscDesc",
  },
  {
    header: "SAP material code",
    accessorKey: "sapMaterialCode",
    key: "sapMaterialCode",
  },
];

export const MB_LG_LOCATION_COLUMNS = [
  {
    header: "Mass balance location group name",
    accessorKey: "mbLocationGroupName",
    key: "mbLocationGroupName",
  },
  {
    header: "Location master name",
    accessorKey: "locationMasterShortName",
    key: "locationMasterShortName",
  },
  {
    header: "Valid from",
    accessorKey: "validFrom",
    key: "validFrom",
  },
  {
    header: "Valid to",
    accessorKey: "validTo",
    key: "validTo",
  },
];

export const COUNTRY_CODE_COLUMNS = [
  {
    header: "Country Name",
    accessorKey: "countryName",
    key: "countryName",
  },
  {
    header: "ISO Country Code",
    accessorKey: "countryCode",
    key: "countryCode",
  },
  {
    header: "SAP Country Code",
    accessorKey: "sapCountryCode",
    key: "sapCountryCode",
  },
];

export const MASS_BALANCE_MATERIALS_COLUMNS = [
  {
    header: "SAP material ID",
    accessorKey: "sapMaterialId",
    key: "sapMaterialId",
  },
  {
    header: "SAP product hierarchy prefix",
    accessorKey: "sapProductHierarchyPrefix",
    key: "sapProductHierarchyPrefix",
  },
  {
    header: "Exclude(Boolean)",
    accessorKey: "exclude",
    key: "exclude",
  },
  {
    header: "Valid from",
    accessorKey: "validFrom",
    key: "validFrom",
  },
  {
    header: "Valid to",
    accessorKey: "validTo",
    key: "validTo",
  },
];

export const BUSINESS_PARTNER_COLUMNS = [
  {
    header: "Business partner short name",
    accessorKey: "businessPartnerShortName",
    key: "businessPartnerShortName",
  },
  {
    header: "Business partner long name",
    accessorKey: "businessPartnerLongName",
    key: "businessPartnerLongName",
  },
  {
    header: "SAP customer ID",
    accessorKey: "sourceCustomerId",
    key: "sourceCustomerId",
  },
  {
    header: "SAP vendor ID",
    accessorKey: "sourceVendorId",
    key: "sourceVendorId",
  },
  {
    header: "Customer type",
    accessorKey: "customerType",
    key: "customerType",
  },
];

export const BUSINESS_PARTNER_ADDRESS_COLUMNS = [
  {
    header: "Address description",
    accessorKey: "addressDescription",
    key: "addressDescription",
  },
  {
    header: "Business partner name",
    accessorKey: "businessPartnerName",
    key: "businessPartnerName",
  },
  {
    header: "Location",
    accessorKey: "locationLongName",
    key: "locationLongName",
  },
];

export const LOCATION_DETAILS_COLUMNS = [
  {
    header: "Country",
    accessorKey: "countryName",
    key: "countryName",
  },
  {
    header: "Division",
    accessorKey: "divisionName",
    key: "divisionName",
  },
  {
    header: "Location short name",
    accessorKey: "locationShortName",
    key: "locationShortName",
  },
  {
    header: "Location long name",
    accessorKey: "locationLongName",
    key: "locationLongName",
  },
  {
    header: "Location type",
    accessorKey: "locationType",
    key: "locationType",
  },
  {
    header: "SAP location ID",
    accessorKey: "sapLocationCode",
    key: "sapLocationCode",
  },
];

export const MB_CONFIG_COLUMNS = [
  {
    header: "Country",
    accessorKey: "countryCode",
    key: "countryCode",
  },
  {
    header: "Balance type",
    accessorKey: "mbBalanceType",
    key: "mbBalanceType",
  },
  {
    header: "Mass balance location group",
    accessorKey: "mbLocationGroup",
    key: "mbLocationGroup",
  },
  {
    header: "Balance cycle",
    accessorKey: "balanceCycle",
    key: "balanceCycle",
  },
  {
    header: "Balance group name",
    accessorKey: "mbBalanceGroupName",
    key: "mbBalanceGroupName",
  },
  {
    header: "Business partner code",
    accessorKey: "businessPartnerCode",
    key: "businessPartnerCode",
  },
  {
    header: "Business partner name",
    accessorKey: "businessPartnerName",
    key: "businessPartnerName",
  },
  {
    header: "Division code",
    accessorKey: "divisionCode",
    key: "divisionCode",
  },
  {
    header: "Valid from",
    accessorKey: "validFrom",
    key: "validFrom",
  },
  {
    header: "Valid to",
    accessorKey: "validTo",
    key: "validTo",
  },
];

export const PROCESSING_UNIT_COLUMNS = [
  {
    header: "Location short name",
    accessorKey: "locationShortName",
    key: "locationShortName",
  },
  {
    header: "Processing unit",
    accessorKey: "processingUnitCode",
    key: "processingUnitCode",
  },
];

export const TRANSPORTATION_EMISSIONS_COLUMNS = [
  {
    header: "Country",
    accessorKey: "countryCode",
    key: "countryCode",
  },
  {
    header: "Division",
    accessorKey: "divisionCode",
    key: "divisionCode",
  },
  {
    header: "Sending location ID",
    accessorKey: "sendingLocationCode",
    key: "sendingLocationCode",
  },
  {
    header: "Sending location name",
    accessorKey: "sendingLocationName",
    key: "sendingLocationName",
  },
  {
    header: "Receiving location ID",
    accessorKey: "receivingLocationCode",
    key: "receivingLocationCode",
  },
  {
    header: "Receiving location name",
    accessorKey: "receivingLocationName",
    key: "receivingLocationName",
  },
  {
    header: "MOT name",
    accessorKey: "motName",
    key: "motName",
  },
  {
    header: "Emission factor",
    accessorKey: "emissionFactor",
    key: "emissionFactor",
  },
  {
    header: "Emission UoM",
    accessorKey: "emissionUom",
    key: "emissionUom",
  },
  {
    header: "Valid from",
    accessorKey: "validFrom",
    key: "validFrom",
  },
  {
    header: "Valid to",
    accessorKey: "validTo",
    key: "validTo",
  },
];

export const PRODUCT_BASELINE_EMISSION_DETAIL_COLUMNS = [
  {
    header: "Country",
    accessorKey: "countryCode",
    key: "countryCode",
  },
  {
    header: "Division",
    accessorKey: "divisionCode",
    key: "divisionCode",
  },
  {
    header: "Material short name",
    accessorKey: "materialShortName",
    key: "materialShortName",
  },
  {
    header: "Baseline emission",
    accessorKey: "baselineEmission",
    key: "baselineEmission",
  },
  {
    header: "Unit of measure",
    accessorKey: "uomCode",
    key: "uomCode",
  },
  {
    header: "Valid from",
    accessorKey: "validFrom",
    key: "validFrom",
  },
  {
    header: "Valid to",
    accessorKey: "validTo",
    key: "validTo",
  },
];

export const LOCATION_YIELD_DETAILS_COLUMNS = [
  {
    header: "Country",
    accessorKey: "countryCode",
    key: "countryCode",
  },
  {
    header: "Division",
    accessorKey: "divisionCode",
    key: "divisionCode",
  },
  {
    header: "Location name",
    accessorKey: "locationShortName",
    key: "locationShortName",
  },
  {
    header: "Processing unit",
    accessorKey: "processingUnitCode",
    key: "processingUnitCode",
  },
  {
    header: "Material short name",
    accessorKey: "materialShortName",
    key: "materialShortName",
  },
  {
    header: "Yield type - Certification",
    accessorKey: "yieldType",
    key: "yieldType",
  },
  {
    header: "Yield ratio",
    accessorKey: "yieldRatio",
    key: "yieldRatio",
  },
  {
    header: "Valid from",
    accessorKey: "validFrom",
    key: "validFrom",
  },
  {
    header: "Valid to",
    accessorKey: "validTo",
    key: "validTo",
  },
];

export const CERTIFICATE_SCHEME_COLUMNS = [
  {
    header: "Country",
    accessorKey: "countryName",
    key: "countryName",
  },
  {
    header: "Division",
    accessorKey: "divisionName",
    key: "divisionName",
  },
  {
    header: "Certification name",
    accessorKey: "certificationSchemeName",
    key: "certificationSchemeName",
  },
  {
    header: "Certification code",
    accessorKey: "certificationSchemeCode",
    key: "certificationSchemeCode",
  },
  {
    header: "Recognized by EU Commission",
    accessorKey: "euCommissionRecognizedFlag",
    key: "euCommissionRecognizedFlag",
    cell: (cell) => {
      const value = cell.getValue();
      return value === true ? "Yes" : "No";
    },
  },
];

export const NATIONAL_REGISTRY_COLUMNS = [
  {
    header: "Country",
    accessorKey: "countryCode",
    key: "countryCode",
  },
  {
    header: "Division",
    accessorKey: "divisionCode",
    key: "divisionCode",
  },
  {
    header: "System name",
    accessorKey: "systemName",
    key: "systemName",
  },
  {
    header: "Material Id",
    accessorKey: "materialCode",
    key: "materialCode",
  },
  {
    header: "National registry code",
    accessorKey: "nationalRegistryCode",
    key: "nationalRegistryCode",
  },
  {
    header: "Soil Criteria",
    accessorKey: "soilCriteria",
    key: "soilCriteria",
  },
  {
    header: "Land Criteria",
    accessorKey: "landCriteria",
    key: "landCriteria",
  },
  {
    header: "Forestry Criteria",
    accessorKey: "forestryCriteria",
    key: "forestryCriteria",
  },
];

export const PRODUCT_DEFAULT_VALUES_COLUMNS = [
  {
    header: "Country",
    accessorKey: "countryCode",
    key: "countryCode",
  },
  {
    header: "Division",
    accessorKey: "divisionCode",
    key: "divisionCode",
  },
  {
    header: "Material short name",
    accessorKey: "materialShortName",
    key: "materialShortName",
  },
  {
    header: "Default value type",
    accessorKey: "defaultValueType",
    key: "defaultValueType",
  },
  {
    header: "Default value",
    accessorKey: "defaultValue",
    key: "defaultValue",
  },
  {
    header: "Unit of measure",
    accessorKey: "uomCode",
    key: "uomCode",
  },
  {
    header: "Valid from",
    accessorKey: "validFrom",
    key: "validFrom",
  },
  {
    header: "Valid to",
    accessorKey: "validTo",
    key: "validTo",
  },
];

export const COUNTRY_VARIATION_COLUMNS = [
  {
    header: "Country name",
    accessorKey: "countryName",
    key: "countryName",
  },
  {
    header: "UK - DFT - Country code",
    accessorKey: "ukDftCountryCode",
    key: "ukDftCountryCode",
  },
  {
    header: "PL- Polish Country Name",
    accessorKey: "plPolishCountryName",
    key: "plPolishCountryName",
  },
  {
    header: "ES - National System Code",
    accessorKey: "esNationalSystemCode",
    key: "esNationalSystemCode",
  },
];

export const PRODUCTION_EMISSION_NEW_COLUMNS = [
  {
    header: "Country",
    accessorKey: "countryCode",
    key: "countryCode",
  },
  {
    header: "Division",
    accessorKey: "divisionCode",
    key: "divisionCode",
  },
  {
    header: "Location short name",
    accessorKey: "locationShortName",
    key: "locationShortName",
  },
  {
    header: "Processing unit",
    accessorKey: "processingUnitCode",
    key: "processingUnitCode",
  },
  {
    header: "Emission factor",
    accessorKey: "emissionFactor",
    key: "emissionFactor",
  },
  {
    header: "Emission Uom",
    accessorKey: "emissionUom",
    key: "emissionUom",
  },
  {
    header: "Valid from",
    accessorKey: "validFrom",
    key: "validFrom",
  },
  {
    header: "Valid to",
    accessorKey: "validTo",
    key: "validTo",
  },
];

export const CERTIFICATE_SCHEME_VARIATION_COLUMNS = [
  {
    header: "Certification name",
    accessorKey: "certificationSchemeName",
    key: "certificationSchemeName",
  },
  {
    header: "UK - DFT - Certification code",
    accessorKey: "schemeVariationValue",
    key: "schemeVariationValue",
  },
];

export const TAX_PLANT_REGISTRY_COLUMNS = [
  {
    header: "Company ID",
    accessorKey: "companyCode",
    key: "companyCode",
  },
  {
    header: "Legal entity",
    accessorKey: "legalEntity",
    key: "legalEntity",
  },
  {
    header: "Plant name",
    accessorKey: "plantName",
    key: "plantName",
  },
  {
    header: "Excise registeration number",
    accessorKey: "registrationNumber",
    key: "registrationNumber",
  },
  {
    header: "Tax liability AS",
    accessorKey: "taxLiability",
    key: "taxLiability",
  },
  {
    header: "ED group",
    accessorKey: "eDGroup",
    key: "eDGroup",
  },
  {
    header: "MOT scope",
    accessorKey: "mot",
    key: "mot",
  },
  {
    header: "Main custom office",
    accessorKey: "mainCustomsOffice",
    key: "mainCustomsOffice",
  },
];

export const CERTIFICATE_ID_COLUMNS = [
  {
    header: "Country",
    accessorKey: "countryCode",
    key: "countryCode",
  },
  {
    header: "Division",
    accessorKey: "divisionCode",
    key: "divisionCode",
  },
  {
    header: "Business partner ID",
    accessorKey: "businessPartnerCode",
    key: "businessPartnerCode",
  },
  {
    header: "Business partner name",
    accessorKey: "businessPartnerName",
    key: "businessPartnerName",
  },
  {
    header: "Address",
    accessorKey: "address",
    key: "address",
  },
  {
    header: "Certification Scheme",
    accessorKey: "certificationSchemeCode",
    key: "certificationSchemeCode",
  },
  {
    header: "Certification type",
    accessorKey: "certificationType",
    key: "certificationType",
  },
  {
    header: "Mass balance type",
    accessorKey: "mbTypeCode",
    key: "mbTypeCode",
  },
  {
    header: "Certificate ID",
    accessorKey: "certificateId",
    key: "certificateId",
  },
  {
    header: "Valid from",
    accessorKey: "validFrom",
    key: "validFrom",
  },
  {
    header: "Valid to",
    accessorKey: "validTo",
    key: "validTo",
  },
];
