import {ReactTable} from "@bphxd/ds-core-react";
import PropTypes from "prop-types";
import React from "react";
import "./ConfigurationTable.scss";

const ConfigurationTable = ({columns, data}) => {
  return (
    <div className="bp-core config-table-table">
      <div className="bp-component-table">
        <ReactTable
          showPaginationTop={false}
          responsive
          columns={columns}
          data={data}
          tableClassName="dashboard-table table-column-sticky header-color"
        />
      </div>
    </div>
  );
};

ConfigurationTable.propTypes = {
  columns: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired,
};

export default ConfigurationTable;
