import {Close, Datepicker} from "@bphxd/ds-core-react";
import Form from "modules/common/FormNew";
import PropTypes from "prop-types";
import {useEffect, useState} from "react";
import {useFormContext} from "react-hook-form";
import {
  Button,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";

const VersionModalDisplay = ({
  showUploadModal,
  setUploadModal,
  titleHead,
  initialComment,
  initialValidFrom,
  setDropdownIndex,
  onSubmit,
  commentPlaceholder,
  validFromPlaceholder,
}) => {
  const [inputValue, setInputValue] = useState("");
  const [startDate, setStartDate] = useState(
    initialValidFrom ? new Date(initialValidFrom) : new Date(),
  );

  const {
    register,
    handleSubmit,
    formState: {errors, isValid},
    setError,
    setValue,
    trigger,
    watch,
  } = useFormContext();
  const computeProps = (name, options) => {
    const {ref, ...props} = register(name, options);
    return {innerRef: ref, ...props};
  };

  const commentValue = watch("comment");
  const validFromValue = watch("validFrom");

  useEffect(() => {
    if (initialComment) {
      setInputValue(initialComment);
    }
    if (initialValidFrom) {
      setStartDate(new Date(initialValidFrom));
    } else {
      setStartDate(new Date());
    }
  }, [initialComment, initialValidFrom]);

  useEffect(() => {
    trigger("comment");
  }, [commentValue, trigger]);

  useEffect(() => {
    setValue("validFrom", startDate);
    trigger("validFrom");
  }, [startDate, setValue, trigger]);

  return (
    <div>
      <Modal
        isOpen={showUploadModal}
        className="modal-dialog-centered"
        style={{width: "351px"}}
      >
        <ModalHeader
          className="!border-b-[1px] !border-gray-200 text-lg"
          close={
            <Close
              onClick={() => {
                setUploadModal(false);
              }}
            />
          }
        >
          {titleHead}
        </ModalHeader>
        <ModalBody className="py-0">
          <Form
            id="upload-form"
            onSubmit={async (values, event) => {
              onSubmit({
                inputValue: event.target.comment.value,
                startDate: event.target.validFrom.value,
              });

              setUploadModal(false);
            }}
          >
            <div className="w-full flex flex-col gap-7">
              <span className="text-sm"></span>

              <FormGroup>
                <Label for="comment" className="fw-medium text-m">
                  Comment{" "}
                  <span className="p-2 text-sm text-gray-500">optional</span>
                </Label>
                <Input
                  type="input"
                  placeholder={commentPlaceholder}
                  id="comment"
                  name="comment"
                  value={inputValue}
                  invalid={!!errors.comment}
                  {...computeProps("comment", {
                    maxLength: {
                      value: 1000,
                      message: "Comment cannot exceed 1000 characters",
                    },
                  })}
                  onChange={(e) => setInputValue(e.target.value)}
                />
                {errors.comment && (
                  <FormFeedback className="mt-2 !block">
                    {errors.comment.message}
                  </FormFeedback>
                )}
              </FormGroup>

              <FormGroup>
                <Label for="validFrom" className="fw-medium text-sm">
                  Valid from
                </Label>
                <Datepicker
                  className="bp-datepicker-bp-core !placeholder-[#111111a3]"
                  id="validFrom"
                  name="validFrom"
                  invalid={!!errors.validFrom}
                  selected={startDate}
                  onChange={(date) => {
                    setStartDate(date);
                    setValue("validFrom", date);
                    trigger("validFrom");
                  }}
                  placeholderText={setDropdownIndex}
                  placeholder={validFromPlaceholder}
                  options={{defaultDate: startDate}}
                  {...computeProps("validFrom", {
                    required: "Please provide valid from date",
                  })}
                />
                {errors.validFrom && (
                  <FormFeedback className="mt-2 !block">
                    {errors.validFrom.message}
                  </FormFeedback>
                )}
              </FormGroup>
              <ModalFooter className="p-0 d-block mt-5">
                <div className="row g-0 m-0 modal-footer-row">
                  <div className="col-6 d-grid">
                    <Button
                      color="darker-tertiary"
                      size="lg"
                      className="border-0 rounded-0 py-4 opacity-80 opacity-100-hover bg-transparent"
                      onClick={() => {
                        setUploadModal(false);
                      }}
                    >
                      Cancel
                    </Button>
                  </div>
                  <div className="col-6 d-grid">
                    <Button
                      color="darker-tertiary"
                      size="lg"
                      className="border-0 rounded-0 py-4 bg-transparent text-default"
                      type="submit"
                      disabled={!isValid}
                    >
                      Save
                    </Button>
                  </div>
                </div>
              </ModalFooter>
            </div>
          </Form>
        </ModalBody>
      </Modal>
    </div>
  );
};

VersionModalDisplay.propTypes = {
  showUploadModal: PropTypes.bool,
  setUploadModal: PropTypes.func,
  titleHead: PropTypes.string,
  initialComment: PropTypes.string,
  initialValidFrom: PropTypes.string,
  setDropdownIndex: PropTypes.func,
  onSubmit: PropTypes.func,
  commentPlaceholder: PropTypes.string,
  validFromPlaceholder: PropTypes.string,
};

export default VersionModalDisplay;
