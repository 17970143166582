import React from "react";
import PropTypes from "prop-types";
import {Button, Modal, ModalHeader, ModalBody, ModalFooter} from "reactstrap";
import {Close} from "@bphxd/ds-core-react";
import "../index.css";

const PreviewDocumentModal = ({
  showPreview,
  setShowPreview,
  uploadFile,
  handleUpload,
}) => {
  return (
    <Modal
      size="md"
      isOpen={showPreview}
      className="modal-dialog-centered square-preview"
    >
      <ModalHeader
        className="border-b-2 border-gray-200 mb-2 text-xl"
        close={
          <Close
            onClick={() => {
              setShowPreview(false);
            }}
          />
        }
      >
        Add document
      </ModalHeader>
      <ModalBody className="text-center pt-0">
        <iframe
          width="100%"
          height="750px"
          title="View document"
          border="0"
          src={URL.createObjectURL(uploadFile)}
        ></iframe>
      </ModalBody>
      <ModalFooter>
        <div className="text-end">
          <Button
            color="tertiary"
            outline
            type="button"
            onClick={() => {
              setShowPreview(false);
            }}
            className="show mr-2"
            style={{borderRadius: "0"}}
          >
            Cancel
          </Button>
          <Button
            color="standard-primary"
            className="primary-btn"
            style={{borderRadius: "0"}}
            onClick={() => {
              setShowPreview(false);

              handleUpload();
            }}
          >
            Submit
          </Button>
        </div>
      </ModalFooter>
    </Modal>
  );
};

PreviewDocumentModal.propTypes = {
  showPreview: PropTypes.bool.isRequired,
  setShowPreview: PropTypes.func.isRequired,
  uploadFile: PropTypes.object.isRequired,
  handleUpload: PropTypes.func.isRequired,
};

export default PreviewDocumentModal;
